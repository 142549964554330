import {BUILDING_GROUP_TYPE_NON_INTERACTIVE} from '../../../constants';
import {BoardTileStatus} from '../../interfaces/shared';

export const BoardTileStructure = {
  player_tile_id: 0,
  state: BoardTileStatus.INACTIVE,
  resource_left: null,
  player_building: {
    upgrading_from_building: null,
    player_mission: null,
    auto_production_amount: 0,
    player_building_id: 0, // 2272
    upgrade_building_id: null,
    building_id: 0,
    group_type: BUILDING_GROUP_TYPE_NON_INTERACTIVE,
    category_id: 55,
    group: "decorative",
    group_description: "Element dekoracyjny",
    is_moveable: false,
    is_deletable: false,
    moveable_from_level: null,
    deletable_from_level: null,
    tile_type: { type_id: 1, width: 1, height: 1 },
    icon: "", // normal_bungalow
    automatic_product: null,
    automatic_currency: null,
    level: null,
    name: "", // name of the field
    additional_details: null,
    daily_product_productions: [],
    hide_level_information_on_front: true,
    pixel_perfect: true,
    additional_board_data: [],
    built_at: "",
    auto_delete_at: null,
    status: 1,
    variant: null,
    last_production_recipe: null,
    player_building_disaster: null,
    is_start_building: false,
    visual_variant: 1,
    wear: 0,
    show_close_disaster_notification: false,
  },
  tile_id: 0,
  x_pos: 5759,
  y_pos: 0, //-321,
  z_pos: null,
  tile_type: { type_id: 1, width: 1, height: 1 },
  border_bitmask: 0,
  empty_icon: null,
  required_level: 1,
  currency_prices: [],
  product_prices: [],
  resource_amount: null,
  is_center_camera: false,
  empty_buildings_list_message: null,
  empty_icon_pixel_perfect: false,
  production: null,
  open_player_island_id: null,
  player_island_id: null,
  inner_island_player_production_building: null,
  limited_resource_production_currency_prize: null,
  limited_resource_production_product_prize: null,
  inner_island_player_resource_summary: null,
  inner_island_player_building_in_progress: { built_at: null },
};
