import { Injectable } from "@angular/core";
import { createSelector, Store } from "@ngrx/store";
import * as R from "ramda";
import { distinctUntilChanged, map } from "rxjs/operators";

import { AppState } from "../../../../../../store/state";
import { ApiMessageService } from "../../../message/api/core/services/api-message.service";
import { MOCK_UP_TASKS } from "../../../mock-up-task/base/custom/dialogs/mock-slides/mock-slide";
import { ApiQaService } from "../../../qa/api/core/services/api-qa.service";
import { selectPlayerState } from "../../../../../../store/player/selectors";
import { PlayerState } from "../../../../../../store/player/reducer";

@Injectable({
  providedIn: "root",
})
export class BusinessService {
  mockTaskAllQaIds = [];
  mockTaskAllQa = [];
  mockTaskNotFinished = [];
  mockTaskFilterd = [];

  constructor(
    private apiQaService: ApiQaService,
    private apiMessageService: ApiMessageService,
    private store: Store<AppState>
  ) {
    this.subscribeUnreadCount();
  }

  subscribeUnreadCount() {
    this.store
      .select(
        createSelector<AppState, object, any[]>(
          selectPlayerState,
          (state: PlayerState): any[] => state.player.unread_messages
        )
      )
      .pipe(
        map(unread_messages => unread_messages[3]),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.getMessagesMockUp();
      });
  }

  getMessagesMockUp() {
    this.apiMessageService
      .getMessages({
        limit: 100,
        type: 3,
      })
      .subscribe(resp => {
        const messages = resp.body;
        this.mockTaskAllQaIds = messages.filter(m => m.qa_info).map(m => m.qa_info.qa_id);

        this.mockTaskFilterd = MOCK_UP_TASKS.filter(m => R.includes(m.qa_id, this.mockTaskAllQaIds));
        this.getMockTaskAllQa();
      });
  }

  getMockTaskAllQa() {
    this.apiQaService.getQaList({ location: 5 }).subscribe(resp => {
      this.filterOnlyMockQa(resp);
    });
  }

  filterOnlyMockQa(arr) {
    this.mockTaskNotFinished = [];

    this.mockTaskAllQa = arr.filter(qa => R.includes(qa.qa_id, this.mockTaskAllQaIds));
    this.checkMockTaskFinished();
  }

  checkMockTaskFinished() {
    this.mockTaskNotFinished = this.mockTaskAllQa.filter(qa => {
      return !qa.last_attempt || (qa.last_attempt && !qa.last_attempt.finished_at);
    });
  }
}
