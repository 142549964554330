import { createReducer, on } from "@ngrx/store";

import { configFetchFailure, configFetchStart, configFetchSuccess } from "./actions";
import { ApplicationConfig } from "./interfaces/config.state";

export interface ConfigState {
  isLoading: boolean;
  loaded: boolean;
  applicationConfig: ApplicationConfig;
}

export const initialState: ConfigState = {
  isLoading: false,
  loaded: false,
  applicationConfig: {
    default_page: {
      copyright_text: null,
    },
    landing_page: {
      enabled: true,
      start_game_at: null,
      title: "Landing page title",
      text_block: "Landing page text block",
      download_button_text: null,
      download_button_external_url: null,
      download_button_file_url: null,
    },
    auth: {
      login_method: "form",
      form_options: {
        show_change_password_button: true,
        show_reset_password_button: true,
        allow_remember_me: true,
        login_field_label: "auth.username", //fallback do tlumaczen ale golnie warosc z BE
        title_text: "",
        show_registration_link: true,
      },
      show_last_logged_in_dialog: false,
      sso_options: {
        allow_remember_me: true,
        title_text: "",
      },
    },
    hud: {
      element_positions: {
        resource: "right",
        parameter: "top",
        buttons: "left",
      },
      player_details: {
        links: {
          terms: {
            url: "/files/terms",
            name: "Pobierz regulamin",
            visible: true,
          },
          gdpr: {
            url: "/files/gpdr",
            name: "RODO",
            visible: false,
          },
          manual: {
            url: "/files/manual",
            name: "Przewodnik gra",
            visible: true,
          },
          business: {
            url: "/files/business",
            name: "Przewodnik biznes",
            visible: false,
          },
          resource: {
            url: "/files/resource",
            name: "Materiały szkoleniowe",
            visible: false,
          },
          faq: {
            url: "/files/faq",
            name: "FAQ",
            visible: false,
          },
        },
      },
    },
    photobooth: {
      unplayable_allowed_add_photos: false,
    },
    common: {
      contact_email: "",
    },
  },
};

export const configReducer = createReducer(
  initialState,
  on(configFetchStart, (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(configFetchSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      loaded: true,
      applicationConfig: { ...state.applicationConfig, ...action.applicationConfig },
    };
  }),
  on(configFetchFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      loaded: false,
    };
  })
);
