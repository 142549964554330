import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { map } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { ApiBusinessRanksService } from "../../../../api/custom/services/api-business-ranks.service";
import { ApiCoreGameRanksService } from "../../../../api/custom/services/api-game-ranks.service";
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from "../../../../consts/custom/event-dialogs/event-names.const";
import { RankGame } from "../../../../interfaces/custom/rank-game.interface";
import { RANKS_TABS } from "../../../../interfaces/custom/rank-type.interface";

@Component({
  selector: "app-ranking-list",
  templateUrl: "./ranking-list.component.html",
})
export class RankingListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiCoreGameRanksService) apiCoreGameRanksService: ApiCoreGameRanksService;
  @OwInject(ApiBusinessRanksService) apiBusinessRanksService: ApiBusinessRanksService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: {
    location: number;
    name?: string;
    openRankType?: RANKS_TABS;
    showBackButton?: boolean;
  };

  rankList = [];
  isLoading = false;

  ngOnInit() {
    this.isLoading = false;
    this.getRanks();
  }

  clearRanks() {
    this.rankList = [];
  }

  getRanks() {
    this.clearRanks();
    this.isLoading = true;
    this.gameRanks();
  }

  gameRanks() {
    this.apiCoreGameRanksService
      .getGameRanks({ location: this.data.location })
      .pipe(map(this.filterRanksByTitleId.bind(this)), map(this.filterRanksByBranchId.bind(this)))
      .subscribe(
        (resp: RankGame[]) => {
          this.rankList = resp;
          // this.setVisibleRanks();
          this.isLoading = false;
        },
        errResp => {
          this.rankList = [];
          this.dialogService.openAlertErrorApi({ errResp });
          this.isLoading = false;
        }
      );
    // this.useMockedData();
  }

  filterRanksByTitleId(ranks: RankGame[]) {
    ranks = ranks.filter(rank => {
      const parameters = rank?.parameters?.visible_on_title_ids;
      let isVisible = false;
      if (!parameters) return true;
      parameters.forEach(param => {
        if (param === this.playerService.player.title_id.id) {
          isVisible = true;
        }
      });
      return isVisible;
    });
    return ranks;
  }
  filterRanksByBranchId(ranks: RankGame[]) {
    ranks = ranks.filter(rank => {
      const parameters = rank?.parameters?.visible_on_branch_ids;
      let isVisible = false;
      if (!parameters) return true;
      parameters.forEach(param => {
        if (param === this.playerService.player.branch_id) {
          isVisible = true;
        }
      });
      return isVisible;
    });
    return ranks;
  }

  openRankDetails(rank: RankGame) {
    this.openRankDetailsGame(rank);
  }

  openRankDetailsGame(rank: RankGame) {
    if (!rank.last_calculated_at) {
      this.dialogService.openAlert({
        description: "Ranking nie jest jeszcze gotowy. Spróbuj ponownie jutro!",
      });
      return;
    }
    if (this.data.location === 1) {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_DETAILS_WORLD,
        config: {
          data: {
            rankId: rank.rank_edition_id,
            rankGroup: rank.parameters && rank.parameters["rank_group"],
          },
        },
      });
    }
    if (this.data.location === 2) {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_DETAILS_GAME,
        config: {
          data: {
            rankId: rank.rank_edition_id,
            rankGroup: rank.parameters && rank.parameters["rank_group"],
          },
        },
      });
    }
  }

  openRankDescription(rank) {
    this.dialogService.openAlert({
      title: rank.name,
      description: rank.description,
    });
  }
}
