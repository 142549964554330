import { MAIN_BOOT_SCENE } from "../game-engine/scenes-main/main.constants";
import { WORLD_BOOT_SCENE } from "../game-engine/scenes-world/world.constants";
import {
  CUSTOM_TILE_HEIGHT,
  CUSTOM_TILE_HOVER_TYPE_ANCHORS,
  CUSTOM_TILE_HOVER_TYPES,
  CUSTOM_TILE_MAP_HEIGHT,
  CUSTOM_TILE_MAP_WIDTH,
  CUSTOM_TILE_TOOLTIP_OFFSET,
  CUSTOM_TILE_TYPE_POLYGON,
  CUSTOM_TILE_WIDTH,
} from './game-custom.constants';
import {
  PROMENADE_BUILDING_ANCHORS, PROMENADE_CUSTOM_TILE_MENU_POSITION_OFFSETS,
  PROMENADE_TILE_MENU_POSITION_OFFSET_BY_BUILDING_ICON, PROMENADE_TILE_TOOLTIP_POSITION_OFFSET_BY_BUILDING_ICON
} from '../game-engine/scenes-promenade/promenade-tiles.config';

export const BOOT_STATE_NAME = "BOOT_STATE_NAME";
export const MAIN_STATE_NAME = "MAIN_STATE_NAME";
export const TREASURES_STATE_NAME = "TREASURES_STATE_NAME";

export const DISCOVER_STATE_NAME = "DISCOVER_STATE_NAME";

export const TILE_MAP_WIDTH = CUSTOM_TILE_MAP_WIDTH || 128;
export const TILE_MAP_HEIGHT = CUSTOM_TILE_MAP_HEIGHT || 128;

export const TILE_WIDTH = CUSTOM_TILE_WIDTH || 128;
export const TILE_HEIGHT = CUSTOM_TILE_HEIGHT || 74;

export const ISO_PROJECTION = TILE_HEIGHT / TILE_WIDTH;

export const ISO_LAYER_TYPE_TILE = "tilelayer";
export const ISO_LAYER_TYPE_OBJECT = "objectgroup";

export const BOARD_TILE_SIZE = 4;
export const BOARD_TILE_WIDTH = TILE_WIDTH * BOARD_TILE_SIZE;
export const BOARD_TILE_HEIGHT = TILE_HEIGHT * BOARD_TILE_SIZE;

export const ISO_BOARD_TILE_WIDTH = TILE_MAP_WIDTH * BOARD_TILE_SIZE;
export const ISO_BOARD_TILE_HEIGHT = TILE_MAP_HEIGHT * BOARD_TILE_SIZE;

export const BOARD_GRID_POSITION_FACTOR = {
  x: 1,
  y: 1,
};

if (location.search.includes("tile-grid")) {
  BOARD_GRID_POSITION_FACTOR.x *= BOARD_TILE_WIDTH;
  BOARD_GRID_POSITION_FACTOR.y *= BOARD_TILE_WIDTH;
}

export const BOARD_TILE_ALPHA = 1;
export const BOARD_TILE_WITH_BUILDING_ALPHA = 1;

export const PHASER_CAMERA_ZOOM = "PHASER_CAMERA_ZOOM";
export const CAMERA_START_TILE = { x: -3, y: 1 };

export const COLOR_DARK_GRAY = "#4d4d4d";
export const COLOR_GREEN = "#09ff6b";
export const COLOR_BROWN = "#5c4500";
export const COLOR_BASE = "#6afbfc";

export const COLOR_MOVE_TILE_BUTTON_CONFIRM = "#314501";
export const COLOR_MOVE_TILE_BUTTON_CANCEL = "#610A0A";

interface PhaserTextStyle {}

export const FONT_AWESOME_TEXT_STYLE = {
  fontFamily: "FAPhaser",
  fontWeight: "900",
};

export const BUILDING_NAME_FONT_STYLES: PhaserTextStyle = {
  color: "#FFF",
  fontSize: 18,
  fontFamily: "Mikado",
  align: "center",
  fontWeight: "bold",
  boundsAlignH: "bottom",
  wordWrapWidth: 200,
  wordWrap: true,
  boundsAlignV: "bottom",
};

export const TILE_TOOLTIP_UPGRADE_TEXT_STYLES = {
  ...BUILDING_NAME_FONT_STYLES,
  color: COLOR_GREEN,
};

export const BUILDING_LEVEL_FONT_STYLES = {
  ...BUILDING_NAME_FONT_STYLES,
  fontSize: 14,
};

export const BUTTON_ICON_TEXT_STYLE = {
  ...FONT_AWESOME_TEXT_STYLE,
  color: COLOR_BASE,
  align: "center",
};

export const BOARD_TILE_ACTIVE = "active";
export const BOARD_TILE_LOCKED = "locked";
export const BOARD_TILE_BUYABLE = "buyable";
export const BOARD_TILE_DISABLED = "disabled";

export const BOARD_TILE_STATES = {
  [BOARD_TILE_ACTIVE]: BOARD_TILE_ACTIVE,
  [BOARD_TILE_BUYABLE]: BOARD_TILE_BUYABLE,
  [BOARD_TILE_LOCKED]: BOARD_TILE_LOCKED,
  [BOARD_TILE_DISABLED]: BOARD_TILE_DISABLED,
};

export const PLAYER_BUILDING_STATUS_BUILD_IN_PROGRESS = 0;
export const PLAYER_BUILDING_STATUS_BUILT = 1;
export const PLAYER_BUILDING_STATUS_UPGRADED = -1;
export const PLAYER_BUILDING_STATUS_DEMOLISHED = -5;
export const PLAYER_BUILDING_STATUS_AUTODELETE = -10;

export const PLAYER_BUILDING_STATUSES = {
  [PLAYER_BUILDING_STATUS_BUILD_IN_PROGRESS]: PLAYER_BUILDING_STATUS_BUILD_IN_PROGRESS,
  [PLAYER_BUILDING_STATUS_BUILT]: PLAYER_BUILDING_STATUS_BUILT,
  [PLAYER_BUILDING_STATUS_UPGRADED]: PLAYER_BUILDING_STATUS_UPGRADED,
  [PLAYER_BUILDING_STATUS_DEMOLISHED]: PLAYER_BUILDING_STATUS_DEMOLISHED,
  [PLAYER_BUILDING_STATUS_AUTODELETE]: PLAYER_BUILDING_STATUS_AUTODELETE,
};

export const BUILDING_GROUP_TYPE_PRODUCTION = "production";
export const BUILDING_GROUP_TYPE_SPECIAL = "special";
export const BUILDING_GROUP_TYPE_WITH_PROBABILITY = "building-with-probability";
export const BUILDING_GROUP_TYPE_SPECIAL_INCREASE = "special-increase";
export const BUILDING_GROUP_TYPE_GASTRONOMY = "gastronomy";
export const BUILDING_GROUP_TYPE_DIVE = "dive";
export const BUILDING_GROUP_TYPE_HIDDEN = "hidden";
export const BUILDING_GROUP_TYPE_AUTO_PRODUCTION = "auto-production";
export const BUILDING_GROUP_TYPE_DAILY_PRODUCTION = "daily-production";
export const BUILDING_GROUP_TYPE_NON_INTERACTIVE = "no-interaction";
export const BUILDING_GROUP_TYPE_META_PRODUCTION = "meta-production";

export const MAP_ATLAS_NAME = "map-atlas";

export enum TileTooltipType {
  UPGRADE = "upgrade",
  COLLECT = "collect",
  WARNING = "warning",
  ALERT = "alert",
  EMPTY = "empty",
  PRODUCTION = "production",
  PRODUCTION_FINISHED = "active",
  MISSION = "mission",
}

export enum ProductionStatus {
  InProgress = "in-progress",
  Finished = "active",
}

export const GAME_EVENTS = {
  BUILDING_INFO: "BUILDING_INFO",
  BUILDING_PRODUCTION: "BUILDING_PRODUCTION",
  BUILDING_AUTO_PRODUCTION: "BUILDING_AUTO_PRODUCTION",
  BUILDING_DAILY_PRODUCTION: "BUILDING_DAILY_PRODUCTION",
  BUILDING_GASTRO: "BUILDING_GASTRO",
  BUILDING_INTERIOR: "BUILDING_INTERIOR",
  BUILDING_DELETE: "BUILDING_DELETE",
  BUILDING_MOVE: "BUILDING_MOVE",
  BUILDING_UPGRADE: "BUILDING_UPGRADE",
  BUILDING_SPECIAL_UPGRADE: "BUILDING_SPECIAL_UPGRADE",
  BUILDING_UPGRADE_WITH_PROBABILITY: "BUILDING_UPGRADE_WITH_PROBABILITY",
  BUILDING_UPGRADE_SPECIAL_INCREASE: "BUILDING_UPGRADE_SPECIAL_INCREASE",
  BUILDING_MUSEUM: "BUILDING_MUSEUM",
  TILE_CLICK: "TILE_CLICK",
  OPEN_TILE_MENU: "OPEN_TILE_MENU",
  OPEN_FUNCTIONAL: "OPEN_FUNCTIONAL",
  OPEN_DIALOG: "OPEN_DIALOG",
  OPEN_BHP: "OPEN_BHP",
  OPEN_PLAYER_MISSION: "OPEN_PLAYER_MISSION",
  START_SCENE: "START",
  MAP_CHANGE: 'MAP_CHANGE'
};

export const BUILDINGS_PATH = "buildings/";
export const BUILDINGS_EXTENSIONS_PATH = "buildings-extensions/";
export const ICONS_PATH = "buildings/icons_buildings/";

export const SUPPLY_SHIP_CAPACITY_BUILDING_GROUP = "xx_transporter_capacity";
export const SUPPLY_SHIP_POWER_BUILDING_GROUP = "xx_transporter_power";

export const BUILDING_PARAMETERS_DEFINITIONS = [
  "icon",
  "level",
  "version",
  "anchor",
  "x",
  "y",
  "animation",
  "animParam",
];

export const TILE_HOVER_TYPES = {
  "1x1": "tile-hover-1.png",
  "2x2": "tile-hover-2.png",
  "3x2": "tile-hover-2.png",
  "1x3": "tile-hover-3.png",
  "3x1": "tile-hover-4.png",
  ...CUSTOM_TILE_HOVER_TYPES,
};

export const TILE_HOVER_TYPE_ANCHORS = {
  "1x1": { x: 0.5, y: 1 },
  "2x2": { x: 0.5, y: 1 },
  "3x2": { x: 0.5, y: 1 },
  "1x3": { x: 0.25, y: 1 },
  "3x1": { x: 0.75, y: 1 },
  ...CUSTOM_TILE_HOVER_TYPE_ANCHORS,
};

export const TILE_TYPE_POLYGON: { [type: string]: string } = {
  "1x1": `0 0 ${-BOARD_TILE_WIDTH / 2} ${-BOARD_TILE_HEIGHT / 2} 0 ${-BOARD_TILE_HEIGHT} ${BOARD_TILE_WIDTH / 2} -${BOARD_TILE_HEIGHT / 2}`,
  "1x3": `0 0 ${-BOARD_TILE_WIDTH / 2} ${-BOARD_TILE_HEIGHT / 2} ${BOARD_TILE_WIDTH} ${-BOARD_TILE_HEIGHT * 2} ${BOARD_TILE_WIDTH * 1.5} -${
    BOARD_TILE_HEIGHT * 1.5
  }`,
  "2x2": `0 0 ${-BOARD_TILE_WIDTH} ${-BOARD_TILE_HEIGHT} 0 ${-BOARD_TILE_HEIGHT * 2} ${BOARD_TILE_WIDTH} -${BOARD_TILE_HEIGHT}`,
  "3x1": `0 0 ${-BOARD_TILE_WIDTH * 1.5} ${-BOARD_TILE_HEIGHT * 1.5} ${-BOARD_TILE_WIDTH} ${-BOARD_TILE_HEIGHT * 2} ${
    BOARD_TILE_WIDTH / 2
  } -${BOARD_TILE_HEIGHT / 2}`,
  "3x2": `0 0 ${-BOARD_TILE_WIDTH * 1.5} ${-BOARD_TILE_HEIGHT * 1.5} ${-BOARD_TILE_WIDTH / 2} ${
    -BOARD_TILE_HEIGHT * 2.5
  } ${BOARD_TILE_WIDTH} -${BOARD_TILE_HEIGHT}`,
  "2x1": `0 0 ${-BOARD_TILE_WIDTH} ${-BOARD_TILE_HEIGHT} ${-BOARD_TILE_WIDTH / 2} ${-BOARD_TILE_HEIGHT * 1.5} ${BOARD_TILE_WIDTH / 2} -${
    BOARD_TILE_HEIGHT / 2
  }`,
  ...CUSTOM_TILE_TYPE_POLYGON,
};

export const TILE_TOOLTIP_OFFSET = {
  "1x1": {
    x: 0,
    y: -250,
  },
  "2x1": {
    x: 0,
    y: -250,
  },
  "2x2": {
    x: 0,
    y: -250,
  },
  "3x2": {
    x: 0,
    y: -250,
  },
  "1x3": {
    x: 250,
    y: -450,
  },
  "3x1": {
    x: -250,
    y: -450,
  },
  ...CUSTOM_TILE_TOOLTIP_OFFSET,
};

// tile tooltip
export const TOOLTIP_OFFSET_BY_BUILDING_ICON = {
  railway_station: {
    x: 300,
    y: -300,
  },
  cityhall: {
    x: -150,
    y: -300,
  },
  wholesaler: {
    x: 0,
    y: -300
  },
  ...PROMENADE_TILE_TOOLTIP_POSITION_OFFSET_BY_BUILDING_ICON
};

export const BUILDING_ANCHORS = {
  ...PROMENADE_BUILDING_ANCHORS
};

export const TILE_MENU_POSITION_OFFSETS = {
  "1x1": { x: 0, y: 0 },
  "2x2": { x: 0, y: 0 },
  "3x2": { x: 0, y: 0 },
  "1x3": { x: BOARD_TILE_WIDTH, y: -BOARD_TILE_HEIGHT },
  ...PROMENADE_CUSTOM_TILE_MENU_POSITION_OFFSETS,
};

// tile hover and tile menu
export const TILE_MENU_POSITION_OFFSETS_BY_BUILDING_ICON = {
  normal_playground: { x: 0, y: -100 },
  railway_station: {
    x: 300,
    y: -100,
  },
  cityhall: {
    x: -100,
    y: -100,
  },
  "wholesaler": {
    x: 0,
    y: -50
  },
  powerplant: {
    x: 0,
    y: 280
  },
  ...PROMENADE_TILE_MENU_POSITION_OFFSET_BY_BUILDING_ICON
};

export const PARAMETER_POPULATION = "population";

export const BUILDING_PARAMETERS_MAP = {
  cleaning_cost: {
    name: "Koszy sprzątania",
    icon: "cleaning_cost.png",
  },
  comfort: {
    name: "Komfort",
    icon: "comfort.png",
  },
  entertainment: {
    name: "Rozrywka",
    icon: "entertainment.png",
  },
  gastro: {
    name: "Gastronomia",
    icon: "gastro.png",
  },
  kids_attractions: {
    name: "Atrakcje dla dzieci",
    icon: "kids-attractions.png",
  },
  population: {
    name: "Miejsca noclegowe",
    icon: "population.png",
  },
  prestige: {
    name: "Prestiż kurortu",
    icon: "prestige.png",
  },
  satisfaction: {
    name: "Zadowolenie",
    icon: "fa fa-question",
  },
  water_attractions: {
    name: "Atrakcje wodne",
    icon: "water-attractions.png",
  },
};

export const WAREHOUSE_GROUP_NAME = "nor_warehause_r";
export const WAREHOUSE_GROUP_NAMES = ["nor_warehause_r", "nor_warehause", "nor_warehouse_r", "nor_warehouse"];

export const BHP_BUILDING_NAME = "normal_apartment";
export const EXCAVATIONS_ICON_NAME = "hist_excavations";

export const ATTRIBUTE_NAMES = {
  INCREASE_TRANSPORTER_POWER: "increase-transporter-power",
  INCREASE_TRANSPORTER_CAPACITY: "increase-transporter-capacity",
};

export const TILE_DEPTH_BY_BUILDING_ICON = {
  [EXCAVATIONS_ICON_NAME]: -1,
};

export const CHEST_STATUS = {
  OPENED: 1,
  CLOSED: 2,
  USED: 3,
};

export enum DiscoverPlaceTypes {
  Shallow = "shallow",
  Medium = "medium",
  Deep = "deep",
}

export const DISCOVER_PLACE_SHALLOW_GROUP_TYPE = 1;
export const DISCOVER_PLACE_MEDIUM_GROUP_TYPE = 2;
export const DISCOVER_PLACE_DEEP_GROUP_TYPE = 3;

export const DISCOVER_PLACES_NAMES = {
  [DISCOVER_PLACE_SHALLOW_GROUP_TYPE]: DiscoverPlaceTypes.Shallow,
  [DISCOVER_PLACE_MEDIUM_GROUP_TYPE]: DiscoverPlaceTypes.Medium,
  [DISCOVER_PLACE_DEEP_GROUP_TYPE]: DiscoverPlaceTypes.Deep,
};

export const DISCOVER_PLACES_VERSIONS = {
  [DISCOVER_PLACE_SHALLOW_GROUP_TYPE]: "a",
  [DISCOVER_PLACE_MEDIUM_GROUP_TYPE]: "b",
  [DISCOVER_PLACE_DEEP_GROUP_TYPE]: "c",
};

export const DISCOVER_PLACES_DISPLAY_NAMES = {
  [DISCOVER_PLACE_SHALLOW_GROUP_TYPE]: "PŁYTKIE",
  [DISCOVER_PLACE_MEDIUM_GROUP_TYPE]: "SZELFOWE",
  [DISCOVER_PLACE_DEEP_GROUP_TYPE]: "GŁĘBINOWE",
};

export const BUILDINGS_LEVEL_COLOR = {
  "lvl-1": {
    bg: "lvl-1.png",
    text: "#ffffff",
  },
  "lvl-2": {
    bg: "lvl-2.png",
    text: "#ffffff",
  },
  "lvl-3": {
    bg: "lvl-3.png",
    text: "#ffffff",
  },
  "lvl-4": {
    bg: "lvl-4.png",
    text: "#ffffff",
  },
  "lvl-5": {
    bg: "lvl-5.png",
    text: "#ffffff",
  },
  "lvl-6": {
    bg: "lvl-6.png",
    text: "#ffffff",
  },
  "lvl-7": {
    bg: "lvl-7.png",
    text: "#ffffff",
  },
  "lvl-8": {
    bg: "lvl-8.png",
    text: "#ffffff",
  },
  "lvl-9": {
    bg: "lvl-9.png",
    text: "#ffffff",
  },
  "lvl-10": {
    bg: "lvl-10.png",
    text: "#ffffff",
  },
};

export enum TileMenuType {
  Normal,
  DiscoverPlace,
}

export const AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME = `auto-production-hourly-performance`;
export const AUTO_PRODUCTION_STORAGE_SIZE_ATTRIBUTE_NAME = `storage-size`;

export enum BoardViewMode {
  Isometric,
  Ortogonal,
}

export enum MapType {
  Main = 1,
  World = 2,
}

export const MapTypeKeyMap = {
  [MapType.Main]: MAIN_BOOT_SCENE,
  [MapType.World]: WORLD_BOOT_SCENE,
};

export const TILE_TOOLTIP_DEPTH = 10000;
export const TILE_HOVER_DEPTH = 20000;
export const TILE_BUILDING_LEVEL_LAYER = 30000;
export const TILE_MENU_DEPTH = 40000;
