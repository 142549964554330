import { CurrencyDefinition } from "../../../../core/interfaces/currency";
import { ProductDetailsBalance } from "../../../player/interfaces/product.interface";
import { IsoMapLayer } from "../classes/core/IsoMapLayer";

export interface MyPoint {
  x: number;
  y: number;
}

export enum BoardTileStatus {
  ACTIVE = "active",
  BUYABLE = "buyable",
  LOCKED = "locked",
  INACTIVE = "inactive" // decorative
}

export interface BoardConfig {
  offset?: PositionOffset;
  yAxisSign?: number;
}

export interface PositionOffset {
  x: number;
  y: number;
}

export interface MapType {
  config_name: string;
  player_island_id: number;
  type: string;
}

export interface CenterMap extends MapType {
  tile_id: number;
}

export interface ProductOrCurrencyCombinedData {
  product: ProductDetailsBalance;
  amount: number;
  currency: CurrencyDefinition;
}
