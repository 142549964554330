// used to add triggers on which decoration buildings will be added
import {PromenadeCleanup, PromenadeCreationTriggers, PromenadeCreators} from '../scenes-promenade/triggers/tile-creation-triggers';
import {PromenadeTilesReposition} from '../scenes-promenade/promenade-tiles.config';

export const TileCreationTriggers = {
  ...PromenadeCreationTriggers
}

// used to add special buildings
export const TileCreators = {
  ...PromenadeCreators
}

export const TileCleaners = {
  ...PromenadeCleanup
}

export const TileReposition = {
  ...PromenadeTilesReposition
}
