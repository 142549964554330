import { Injectable } from "@angular/core";
import {CanActivate, Router} from '@angular/router';

import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {selectUtilityToken} from '../../../../store/utility/selectors';
import {catchError, map, take} from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class TokenAuthGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store.select(selectUtilityToken).pipe(
      take(1),
      map(authState => {
        // if there is token then dont allow to move there
        if (authState?.tokenObject?.token) {
          this.router.navigate(['player-choose']);
          return false;
        }
        return true;
      }),
      catchError(() => {
        return of(true);
      })
    );
  }
}
