import { CustomDialogEvent } from "../../../../../../../core/classes/custom-dialog-event.class";
import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import { PlayerDetailsComponent } from "../../../../hud/base/core/dialogs/player-details/player-details.component";
import { MissionDetailsComponent } from "../../../base/core/dialogs/mission-details/mission-details.component";
import { CustomMissionsComponent } from "../../../base/custom/dialogs/missions/custom-missions.component";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../core/event-dialogs/event-names";

export const EVENT_DIALOGS_MISSION_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_MISSION.MISSIONS]: CustomMissionsComponent,
  [EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS]: new CustomDialogEvent(({ data }) => {
    return data?.mission?.slot?.mission_slot_id === 1 ? PlayerDetailsComponent : MissionDetailsComponent;
  }),
};
