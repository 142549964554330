import { BoardTileStructure } from "../templates/board-tile.structure";
import {PROMENADE_ROAD_GROUP_NAME} from '../promenade.constants';

export const PROMENADE_CONFIG = {
  leftSegments: 9, // how many segments had to be generated in this direction
  rightSegments: 10,
  topSegments: 6,
  leftSegmentsAmount: 3, // determines how many graphics do we have in this direction
  rightSegmentsAmount: 4,
  topSegmentsAmount: 3,
  // board tile structure stuff
  player_tile_id: 92000,
  player_building_id: 96000,
  building_id: 86000,
  tile_id: 82000,
};

export class PromenadeRoadsClass {
  config = {
    level: -1,
    segmentName: "",
    segmentDirection: "",
    buildAt: "",
    promenadeXPos: 0,
    promenadeYPos: 0,
    boardTiles: [],
    playerIslandId: null
  };

  constructor(boardTiles) {
    const promenade = boardTiles.find(x => x?.player_building?.group === "normal_promenade");
    if (promenade) {
      this.config.boardTiles = boardTiles;
      this.config.level = promenade.player_building.level;
      this.config.segmentName = promenade.player_building.group;
      this.config.buildAt = promenade.player_building.build_at;
      this.config.promenadeXPos = promenade.x_pos;
      this.config.promenadeYPos = promenade.y_pos;
      this.config.playerIslandId = promenade.player_island_id;
    }
  }

  generateRoads() {
    const boardTilesArr = [];
    let counter = 0;

    /* these blocks are essential - prevents duplicated tiles */
    // no promenade
    if (this.config.level === -1) {
      return [];
    }

    // segments already added
    const examplePromenadeSegment = this.config.boardTiles.find(x => x.tile_id === PROMENADE_CONFIG.tile_id + 1);
    if (examplePromenadeSegment) {
      return [];
    }

    for (let i = 0; i < PROMENADE_CONFIG.leftSegments; i++) {
      boardTilesArr.push(
        this.generateRoadBoardTile(
          "left",
          i,
          counter,
          this.determineSegmentType(i, PROMENADE_CONFIG.leftSegments, PROMENADE_CONFIG.leftSegmentsAmount),
          this.config.playerIslandId
        )
      );
      counter++;
    }

    for (let i = 0; i < PROMENADE_CONFIG.topSegments; i++) {
      console.log("top" + 1);
      boardTilesArr.push(
        this.generateRoadBoardTile(
          "top",
          i,
          counter,
          this.determineSegmentType(i, PROMENADE_CONFIG.topSegments, PROMENADE_CONFIG.topSegmentsAmount),
          this.config.playerIslandId
        )
      );
      counter++;
    }

    for (let i = 0; i < PROMENADE_CONFIG.rightSegments; i++) {
      boardTilesArr.push(
        this.generateRoadBoardTile(
          "right",
          i,
          counter,
          this.determineSegmentType(i, PROMENADE_CONFIG.rightSegments, PROMENADE_CONFIG.rightSegmentsAmount),
          this.config.playerIslandId
        )
      );
      counter++;
    }

    return boardTilesArr;
  }

  // creates a file which will be added later to the board tiles array in the store
  // segment direction determines axis (X = left/right, Y - top/bottom
  // segmentNumber determines on which segment currently we are
  // counter determines amount of elements already generated, we're using it to give unique ID
  // segment type determines the sprite; there should be 3-4 variants of each one
  generateRoadBoardTile(segmentDirection: string, segmentNumber: number, counter: number, segmentType: number, playerIslandId: number) {
    const tile = JSON.parse(JSON.stringify(BoardTileStructure));
    let counterExtraValue = 0; // add extra value to counter to make it unique

    // board tile config
    tile.player_building.build_at = this.config.buildAt;
    tile.player_building.level = this.config.level;
    tile.player_building.icon = this.determineRoadImage(segmentDirection, segmentType);
    switch (segmentDirection) {
      case "left": {
        tile.x_pos = this.config.promenadeXPos - segmentNumber * 320 - 320; // 320 is a width of promenade
        tile.y_pos = this.config.promenadeYPos;
        counterExtraValue = 100;
        break;
      }
      case "right": {
        tile.x_pos = this.config.promenadeXPos + segmentNumber * 320 + 320;
        tile.y_pos = this.config.promenadeYPos;
        counterExtraValue = 200;
        break;
      }
      case "top": {
        tile.x_pos = this.config.promenadeXPos;
        tile.y_pos = this.config.promenadeYPos - segmentNumber * 320 - 320; // 185 is a height of promenade
        counterExtraValue = 300;
        break;
      }
      default:
        break;
    }

    // unique id's
    tile.player_tile_id = PROMENADE_CONFIG.player_tile_id + counter + (counterExtraValue * 2);
    tile.tile_id = PROMENADE_CONFIG.tile_id + counter + counterExtraValue;
    tile.player_building.player_building_id = PROMENADE_CONFIG.player_building_id + counter;
    tile.player_building.building_id = PROMENADE_CONFIG.building_id + counter;
    tile.group_type = PROMENADE_ROAD_GROUP_NAME;
    tile.player_island_id = playerIslandId;
    return tile;
  }

  determineSegmentType(segmentNumber: number, amountOfSegments: number, amountOfVariants: number) {
    let segmentType = (Math.abs(segmentNumber) % 2) + 1;
    if (Math.abs(segmentNumber) == amountOfSegments - 1) {
      segmentType = amountOfVariants; // the last one is always "ending" graphic
    }
    return segmentType;
  }

  determineRoadImage(direction: string, segmentType: number) {
    // pattern: 'groupname-level-direction-segmentType', for instance "normal_promenade-3-x-2"
    return `${this.config.segmentName}_${this.config.level > 2 ? 3 : 2}_${direction === "top" ? "y" : "x"}_${segmentType}`;
  }
}
