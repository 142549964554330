<div class="global-dialog-core no-transparent">
  <loading></loading>

  <div class="button-close-wrapper">
    <app-button-close></app-button-close>
  </div>

  <div class="button-back-wrapper">
    <app-button-back></app-button-back>
  </div>

  <div class="container loading-opacity">
    {{ data }}
  </div>
</div>
