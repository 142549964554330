import { Directive, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { selectPlayer } from "src/app/store/player/selectors";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { unsubscribeObject } from "../../../../../../core/utility/unsubscribe-array";
import { loginLogout } from "../../../../../../store/auth/login/actions";
import { PlayerDetailsLink } from "../../../../../../store/config/interfaces/config.state";
import { ConfigState } from "../../../../../../store/config/reducer";
import { selectConfigPlayerDetailsLinks, selectConfigState } from "../../../../../../store/config/selectors";
import { AppState } from "../../../../../../store/state";
import { userFetchStart } from "../../../../../../store/user/actions";
import { AuthService } from "../../../../../auth/api/services/auth.service";
import { Player } from "../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { UserService } from "../../../../../user/providers/user.service";
import { GuiService } from "../../../../services/gui.service";
import { EVENT_DIALOGS_NAMES_HUD } from "../../const/core/event-dialogs/event-names.const";

@Directive()
export abstract class AbstractPlayerDetailsComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(UserService) userService: UserService;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(Store) storeConfig: Store<ConfigState>;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractPlayerDetailsComponent>;
  @OwInject(FormBuilder) fb: FormBuilder;
  player: Player;
  links: PlayerDetailsLink[] = [];
  formPush: FormGroup;
  activeTab: number = 2;

  subs = {
    player: null,
    configState: null,
  };
  flag = {
    change_password: null,
  };

  ngOnInit(): void {
    this.store
      .select(selectConfigPlayerDetailsLinks)
      .pipe(take(1))
      .subscribe(res => {
        this.links = res.filter(x => x.visible);
      });
    this.subs.configState = this.store.select(selectConfigState).subscribe(config => {
      this.flag.change_password = config.applicationConfig.auth.form_options.show_change_password_button;
    });
    this.subscribePlayer();
    this.clearForm();
  }

  clearForm() {
    this.formPush = this.fb.group({
      pushEnabled: this.fb.control(this.userService.me.push_enabled),
    });
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(selectPlayer)).subscribe(player => {
      this.player = player;
    });
  }

  openSettings() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_HUD.USER_SETTINGS,
    });
  }

  logout() {
    this.authService.logoutApi().subscribe(() => {
      this.guiService.isSplashShow.next(true);
      this.store.dispatch(loginLogout());
    });
  }

  close() {
    this.matDialogRef.close();
  }

  changePush() {
    const pushEnabled = this.formPush.value.pushEnabled;

    this.userService.changePush(pushEnabled).subscribe(
      () => {
        this.store.dispatch(userFetchStart({}));
      },
      () => {
        this.clearForm();
      }
    );
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
