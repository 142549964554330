import { Component, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";

@Component({
  selector: "ow-object-svg",
  templateUrl: "./ow-object-svg.component.html",
})
export class OwObjectSvgComponent implements OnInit {
  @Input() svgObject: { path: string };
  @Input() svg: string;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.getSvgIcon();
  }

  getSvgIcon() {
    if (!this.svgObject) {
      return;
    }

    fetch(this.svgObject.path)
      .then(resp => resp.text())
      .then(resp => {
        this.svg = resp;
        this.renderSvg();
      });
  }

  renderSvg() {
    setTimeout(() => {
      const element = document.createElement("div");
      element.innerHTML = this.svg.trim();
      this.renderer.appendChild(this.elementRef.nativeElement, element.firstChild);
    });
  }
}
