import { Component, OnInit } from "@angular/core";

import { MissionsComponent } from "../../../core/dialogs/missions/missions.component";

@Component({
  selector: "app-missions",
  templateUrl: "../../../core/dialogs/missions/missions.component.html",
  styleUrls: ["../../../core/dialogs/missions/missions.component.scss"],
})
export class CustomMissionsComponent extends MissionsComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
  }

  public override afterMissionsRequest(): void {
    this.filterMissions();
    super.afterMissionsRequest();
  }

  private filterMissions(): void {
    this.missions = this.missions.filter(mission => mission.slot.mission_slot_id > 1);
  }
}
