<!-- LOGO -->
<ng-container *ngIf="!guiService.isSplashShow.value">
    <div class="logo"></div>

    <loading></loading>

    <div class="wrapper">
        <div class="container loading-opacity">
            <p class="teaser">{{ config.auth.form_options.title_text }}</p>
            <form [formGroup]="form" autocomplete="off">
                <!-- LOGIN FORM -->
                <div
                        [class.error]="form.get('username').invalid && form.get('username').dirty && form.get('username').errors"
                        class="control">
                    <div class="label-flex">
                        <label>{{ config.auth.form_options.login_field_label }}:</label>
                    </div>
                    <input
                            autocomplete="off"
                            class="control-input no-ow-input-directive"
                            formControlName="username"
                            type="text"/>
                </div>

                <div
                        [class.error]="form.get('password').invalid && form.get('password').dirty && form.get('password').errors"
                        class="control">
                    <div class="label-flex">
                        <label>{{ "auth.password" | myTranslate }}:</label>
                    </div>
                    <input
                            autocomplete="off"
                            class="control-input no-ow-input-directive"
                            formControlName="password"
                            type="password"/>
                </div>

                <div *ngIf="config.auth.form_options.allow_remember_me" class="control control-checkbox remember-me-container">
                    <div class="form-checkbox">
                        <input autocomplete="off" formControlName="remember_me" id="remember_me" type="checkbox"/>
                        <div class="checkbox-big">
                          <div class="checkbox">
                            <div class="check"></div>
                          </div>
                        </div>
                    </div>

                    <label for="remember_me">
                        {{ "auth.remember-me" | myTranslate }}
                    </label>
                </div>

                <div class="buttons flex-center">
                    <button (click)="login()" [disabled]="form.invalid" class="base primary">
                        {{ "auth.login.button-send" | myTranslate }}
                    </button>
                </div>

                <!-- LOST PASSWORD -->
                <a class="forgot-password" *ngIf="config.auth.form_options.show_reset_password_button"
                   [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.FORGOT_PASSWORD">
                    {{ "auth.login.forgot-password" | myTranslate }}
                </a>

                <!-- REGISTRATION -->
                <a class="registration" *ngIf="config.auth.form_options.show_registration_link"
                   [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.REGISTRATION">
                    {{ "auth.login.registration" | myTranslate }}
                </a>
            </form>
        </div>
        <a class="contact" [href]="'mailto:' + config?.common?.contact_email">{{ config?.common?.contact_email ?? '' }}</a>
    </div>
    <button
            *ngIf="config.landing_page.enabled"
            class="base primary explore-game"
            [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.LANDING">
        {{ "auth.login.know-your-mission" | myTranslate }}
    </button>
    <button
            *ngIf="(isInstallable.pwa || isInstallable.onlyIOS) && (!window.matchMedia('(display-mode: standalone)').matches)"
            class="base secondary install-game" (click)="addApplicationToHomeScreen()">
        {{ "auth.login.install-app" | myTranslate }}
    </button>
    <div class="ios-install-label" *ngIf="iOSInstallVisibility" (click)="toggleiOSInstallLabelVisibility()">
        <!--  <i class="svg-icon info"></i>-->
        <i class="fa-light fa-circle-info"></i>
        <p>
            {{ "landing.ios-install" | myTranslate }}
            <b>{{ "landing.ios-install-share" | myTranslate }}</b>
            {{ "landing.ios-install-add" | myTranslate }}
            <b>{{ "landing.ios-install-screen" | myTranslate }}</b>
        </p>
    </div>
    <div class="copyright-container">
        <p [innerHTML]="config.default_page.copyright_text"></p>
    </div>
</ng-container>
