import { CommonModule } from "@angular/common";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ReactiveFormsModule, Validators } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { ApiOptions } from "../../../../../../core/interfaces/api-options";
import { selectConfigState } from "../../../../../../store/config/selectors";
import { SharedModule } from "../../../../../shared/shared.module";
import { AbstractRegistrationConfirmAComponent } from "../../../../abstract/registration-confirm/variant-a/abstract-registration-confirm-a.component";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../../../../consts/core/routing-auth-absolute.const";
import { TokenObject } from "../../../../interfaces/token-object";

@Component({
  selector: "app-oauth-confirm",
  templateUrl: "./oauth-confirm.component.html",
  styleUrls: ["./oauth-confirm.component.scss"],
  standalone: true,
  imports: [CommonModule, SharedModule, ReactiveFormsModule, RouterModule],
  encapsulation: ViewEncapsulation.None,
})
export class OauthConfirmComponent extends AbstractRegistrationConfirmAComponent implements OnInit {
  ngOnInit() {
    this.subscribeRoutingParams();
    this.subscribeConfig();
  }

  clearForm() {
    this.form = this.fb.group({
      token: this.fb.control(this.token, [Validators.required]),
      username: this.fb.control(null, [Validators.required, Validators.maxLength(23)]),
      confirm: this.fb.control(null, [Validators.required]),
      confirmRodo: this.fb.control(null, [Validators.required]),
    });
  }

  registrationConfirm() {
    const data: RegistrationConfirmDataRequest = {
      token: this.form.value.token,
      username: this.form.value.username,
    };

    this.confirmRegistrationRequest(data).subscribe(
      (resp: TokenObject) => {
        this.dispatchToken(resp);
        this.routeToPlayerChoose();
      },
      errResp => {
        if (errResp.status === 404) {
          this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.LOGIN]);
        } else {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      }
    );
  }

  subscribeConfig() {
    this.subs.config = this.configStore.select(selectConfigState).subscribe(config => {
      this.config = config.applicationConfig;
    });
  }

  confirmRegistrationRequest(data: RegistrationConfirmDataRequest) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };

    return this.apiService.post("auth/oauth2/confirm", options);
  }

  onPaste(event) {
    event.preventDefault();
  }

  getErrors(controlName) {
    return controlName.invalid && controlName.dirty && controlName.errors;
  }
  getDirty(controlName) {
    return controlName.dirty && controlName.invalid;
  }

  protected readonly AUTH_ROUTING_ABSOLUTE_PATH = AUTH_ROUTING_ABSOLUTE_PATH;
}

interface RegistrationConfirmDataRequest {
  token: string;
  username: string;
}
