import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { IResult, UAParser } from 'ua-parser-js';

import { ConfigState } from "../../store/config/reducer";
import { NativeAppService } from "./native-app.service";
import { fromEvent, throttleTime } from 'rxjs';
import { map } from 'rxjs/operators';

export let mobileGui = false;
export enum GUI {
  mobile,
  tablet,
  desktop,
}

@Injectable({
  providedIn: "root",
})
export class DeviceService implements OnDestroy {
  gui: GUI;
  resizeTimer: number;
  windowScale = 1;
  windowMinHeightPx = 534;
  externalBarPx = 60;
  dialogScale = 1;
  isAllowOrientation: boolean;
  allowOrientation = "landscape";
  deviceDetected: IResult = null;
  windowEventSubscription = null;

  constructor(
    private router: Router,
    private store: Store<ConfigState>,
    private nativeAppService: NativeAppService
  ) {
    this.checkDevice();
    this.setGui();
  }

  checkDevice() {
    this.deviceDetected = new UAParser(window.navigator.userAgent).getResult();
    this.setGui();
  }

  setGui() {
    this.handlerChange();
    this.startOrientationManager();
  }

  setBodyClass() {
    switch (this.deviceDetected.device.type) {
      case 'mobile': {
        this.gui = GUI.mobile;
        break;
      }
      case 'tablet': {
        this.gui = GUI.tablet;
        break;
      }
      default: {
        if (window.innerHeight >= 720 && window.innerWidth >= 1280) {
          this.gui = GUI.desktop;
        } else if (window.innerWidth >= 1024) {
          // low res desktop
          this.gui = GUI.desktop;
        } else {
          // if less than tablet minimum then its mobile
          this.gui = window.innerHeight <= 540 || window.innerWidth <= 960 ? GUI.mobile : GUI.tablet;
        }
        break;
      }
    }

    document.body.classList.remove("mobile");
    document.body.classList.remove("tablet");
    document.body.classList.remove("desktop");
    switch (this.gui) {
      case GUI.mobile:
        document.body.classList.add("mobile");
        break;

      case GUI.tablet:
        document.body.classList.add("tablet");
        break;

      case GUI.desktop:
        document.body.classList.add("desktop");
        break;
    }
  }

  disableMobileGui() {
    this.gui = GUI.desktop;
    this.refreshRouter();
  }

  activeMobileGui() {
    this.gui = GUI.mobile;
    this.refreshRouter();
  }

  refreshRouter() {
    this.handlerChange();
    this.router.navigateByUrl(this.router.url);
  }

  handlerChange() {
    this.setGlobalGui();
    this.setBodyClass();
    this.setMobileWindowMaxHeightAndWidthClass();
    this.setOrientation();
  }

  setOrientation() {
    if (this.gui === GUI.tablet || this.gui === GUI.mobile) {
      if (this.allowOrientation === "landscape") {
        this.isAllowOrientation = window.orientation === -90 || window.orientation === 90;
      }

      if (this.allowOrientation === "portrait") {
        this.isAllowOrientation = window.orientation === 0 || window.orientation === 180;
      }
    }
    if (this.nativeAppService.nativeInterface) {
      this.isAllowOrientation = true;
    }
  }

  setGlobalGui() {
    mobileGui = this.gui === GUI.mobile;
  }

  calcScale() {
    const tempScale = window.innerHeight / (this.windowMinHeightPx + this.externalBarPx);
    this.windowScale = tempScale >= 1 ? 1 : tempScale;
  }

  calcDialogScale() {
    const tempScale = window.innerHeight / 320;
    this.dialogScale = tempScale >= 1 ? 1 : window.innerHeight / 320;
  }

  startOrientationManager() {
    const supportOrientationChange = "onorientationchange" in window;

    if (supportOrientationChange) {
      this.windowEventSubscription = fromEvent(window, 'orientationchange')
        .pipe(
          map(event => ({ event, type: 'orientationchange' })),
          throttleTime(10)
        ).subscribe((res => {
          this.handlerChange();
        }))
    } else {
      this.windowEventSubscription = fromEvent(window, 'resize')
        .pipe(
          map(event => ({ event, type: 'resize' })),
          throttleTime(10)
        ).subscribe(res => {
          this.handlerChange();
        })
    }
  }

  setMobileWindowMaxHeightAndWidthClass() {
    setTimeout(() => {
      const prevStyles = Array.from(document.querySelectorAll('style[mobile="style"]'));
      prevStyles.forEach(element => {
        element.remove();
      });
    }, 0);

    setTimeout(() => {
      setTimeout(() => {
        if (this.gui !== GUI.desktop) {
          this.calcDialogScale();
          const extraSheet = new CSSStyleSheet();
          extraSheet.replaceSync(`
          .mobile div:not(.no-dynamic-scale) > mat-dialog-container,
          .tablet div:not(.no-dynamic-scale) > mat-dialog-container {
            transform: scale(${this.dialogScale}) !important;
          }
        `);
          document.adoptedStyleSheets = [...document.adoptedStyleSheets, extraSheet];
        }
      }, 150);
    });
  }

  ngOnDestroy() {
    if (this.windowEventSubscription) {
      this.windowEventSubscription.unsubscribe();
    }
  }
}
