import * as R from "ramda";

import { environment } from "../../../../../../environments/environment";
import { MAP_ATLAS_NAME } from "../../../constants";
import { MyGame } from "../../classes/core/MyGame";
import { MyScene } from "../../classes/core/MyScene";
import { SceneConfig } from "../../interfaces/scene-config.interface";
import { MAIN_MAIN_SCENE, MAIN_PRELOAD_SCENE, TILE_MENU_ATLAS } from "../main.constants";
import { MAIN_SCENE_CONFIG } from "../main-scene.config";
import { MAIN_SCENE_CUSTOM_CONFIG } from "../main-scene.custom.config";

export class PreloadScene extends MyScene {
  constructor() {
    super({
      key: MAIN_PRELOAD_SCENE,
    });
  }

  assetsToPreload: string[];

  init(data?) {
    this.assetsToPreload = data.assetsToPreload;
    this.passedData = data;
  }

  customPreload() {}

  preload(): void {
    super.preload();
    this.gameService = (this.game as MyGame).gameService;

    this.customPreload();

    const preloadValue = this.add.text(-10000, -10000, `test`, {
      fontSize: "30px",
      fontFamily: "Mikado",
      align: "center",
      fontStyle: "bold",
      color: "#6afbfc",
    });
    preloadValue.setOrigin(0.5);

    this.gameService = (this.sys.game as MyGame).gameService;

    this.load.on("progress", progress => {
      const progressRound = Math.round(100 * progress);
      this.gameService.progressLoading = progressRound;
      // preloadValue.setText(progressRound + '%');
    });

    this.load.on("complete", progress => {
      preloadValue.destroy();
      this.gameService.guiService.isSplashShow.next(false);
    });

    this.assetsToPreload.forEach(asset => {
      if (asset) {
        this.load.image(asset, asset);
      }
    });

    const gl = this.game.renderer["gl"];
    if (gl) {
      console.log("MAX TEXTURE SIZE", gl.getParameter(gl.MAX_TEXTURE_SIZE));
    }

    // @ts-ignore
    const sceneConfig: SceneConfig = R.mergeDeepRight(MAIN_SCENE_CONFIG, MAIN_SCENE_CUSTOM_CONFIG);
    sceneConfig.backgroundImages.forEach(image => {
      this.load.image(
        image.key,
        this.gameService.assetsService.getAssetPath(`${sceneConfig.mapDirectoryName}/${image.filename}`, `map dir: ${sceneConfig.mapDirectoryName} filename: ${image.filename} inside Preload.scene.ts`)
      );
    });

    this.load.image("construction", this.gameService.assetsService.getAssetPath("buildings/construction.png", 'construction.png inside Preload.scene.ts'));

    let mapFilenameSuffix = "main";
    if (this.passedData && this.passedData.islandGroupType) {
      mapFilenameSuffix = this.passedData.islandGroupType;
    }

    const mapAsset = this.gameService.assetsService.getAssetPath(
      `${sceneConfig.mapDirectoryName}/island-${mapFilenameSuffix}.json`,
      `mapDirectoryName ${sceneConfig.mapDirectoryName} or mapFilenameSuffix ${mapFilenameSuffix} in .json of image file inside Preload.scene.ts`
    );
    if (!mapAsset) {
      console.warn("[GAME]", `No tiled file for ${mapFilenameSuffix} island grop type. Fallback to 'main'`);
      mapFilenameSuffix = "main";
    }
    this.tiledMapKey = `island-${mapFilenameSuffix}`;
    this.load.json(
      this.tiledMapKey,
      this.gameService.assetsService.getAssetPath(
        `${sceneConfig.mapDirectoryName}/${sceneConfig.tiledJsonPrefix}-${mapFilenameSuffix}.json`,
        `mapDirectoryName ${sceneConfig.mapDirectoryName} or mapFilenameSuffix ${mapFilenameSuffix} in .json file of tiledMapKey inside Preload.scene.ts`
      )
    );

    if (sceneConfig.mapAtlasDirectory) {
      this.load.atlas(
        MAP_ATLAS_NAME,
        this.gameService.assetsService.getAssetPath(`${sceneConfig.mapAtlasDirectory}/atlas/map-atlas.png`, `map-atlas.png mapAtlasDirectory inside Preload.scene.ts`),
        this.gameService.assetsService.getAssetPath(`${sceneConfig.mapAtlasDirectory}/atlas/map-atlas.json`, `map-atlas.json mapAtlasDirectory inside Preload.scene.ts`)
      );
    }

    if (sceneConfig.tileMenuAtlasDirectory) {
      this.load.atlas(
        TILE_MENU_ATLAS,
        this.gameService.assetsService.getAssetPath(`${sceneConfig.tileMenuAtlasDirectory}/tile-menu-atlas.png`, `tile-menu-atlas.png mapAtlasDirectory inside Preload.scene.ts`),
        this.gameService.assetsService.getAssetPath(`${sceneConfig.tileMenuAtlasDirectory}/tile-menu-atlas.json`, `tile-menu-atlas.json mapAtlasDirectory inside Preload.scene.ts`)
      );
    }

    this.load.atlas(
      "icons-atlas",
      this.gameService.assetsService.getAssetPath("icons-atlas.png", `icons-atlas.png mapAtlasDirectory inside Preload.scene.ts`),
      this.gameService.assetsService.getAssetPath("icons-atlas.json", `icons-atlas.json mapAtlasDirectory inside Preload.scene.ts`)
    );

    this.cameras.main.setBackgroundColor("#12212E");
  }

  create(): void {
    this.gameService.guiService.isSplashShow.next(false);
    this.scene.start(MAIN_MAIN_SCENE, { tiledMapKey: this.tiledMapKey });
  }
}
