// all of these files has to be placed in game.constants
export const PROMENADE_TILE_MENU_POSITION_OFFSET_BY_BUILDING_ICON = {
  special_beach_garbage_1: { x: 250, y: -50 },
  special_beach_garbage_2: { x: 250, y: -50 },
  special_beach_garbage_3: { x: 250, y: -50 },
  'special_pier-1': { x: 450, y: 200 },
  'special_pier-2': { x: 200, y: 350 },
  'special_pier-3': { x: 200, y: 350 },
  'special_pier-4': { x: 50, y: 480 },
  'special_pier-5': { x: 100, y: 450 },
  normal_beach: { x: 200, y: -100 },
  normal_water_playground: { x: 250, y: -50 },
  normal_water_atraction: { x: 250, y: -50 }
}

export const PROMENADE_TILE_TOOLTIP_POSITION_OFFSET_BY_BUILDING_ICON = {
  'special_pier-1': { x: 0, y: -200 },
  'special_pier-2': { x: 230, y: 50 },
  'special_pier-3': { x: 230, y: 30 },
  'special_pier-4': { x: 50, y: 160 },
  'special_pier-5': { x: 100, y: 140 }
}

export const PROMENADE_CUSTOM_TILE_MENU_POSITION_OFFSETS = {
  "3x3": { x: 0, y: -200 },
  "5x5": { x: 0, y: -400 }
}

export const PROMENADE_BUILDING_ANCHORS = {
  "special_pier-1": {
    x: 0.4639865996649916,
    y: 0.6695652173913043,
  },
  "special_pier-2": {
    x: 0.5789473684210526,
    y: 0.5357142857142857,
  },
  "special_pier-3": {
    x: 0.5789473684210526,
    y: 0.5497835497835498,
  },
  "special_pier-4": {
    x: 0.6666666666666667,
    y: 0.4388551604509974,
  },
  "special_pier-5": {
    x: 0.641925777331996,
    y: 0.463960231980116,
  },
}

export const PromenadeTilesReposition = {
  "special_pier-1": { x: 6790, y: 2060 },
  "special_pier-2": { x: 6740, y: 1550 },
  "special_pier-3": { x: 6770, y: 1580 },
  "special_pier-4": { x: 6720, y: 1180 },
  "special_pier-5": { x: 6720, y: 1280 },
}
