import { Directive, Injectable, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";

import { APP_ROUTING_ABSOLUTE_PATH } from "../../../../../consts/routing-app-absolute.const";
import { AbstractInjectBaseComponent } from "../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../core/decorators/ow-inject.decorator";
import { ApiOptions } from "../../../../../core/interfaces/api-options";
import { ApiService } from "../../../../../core/providers/api.service";
import { ApplicationConfig } from "../../../../../store/config/interfaces/config.state";
import { selectConfigOfApplication, selectConfigState } from "../../../../../store/config/selectors";
import { AppState } from "../../../../../store/state";
import { utilitySetToken } from "../../../../../store/utility/actions";
import { GuiService } from "../../../../game/services/gui.service";
import { DialogService } from "../../../../shared/providers/dialog.service";
import { AuthService } from "../../../api/services/auth.service";
import { checkPasswordTheSame } from "../../../helpers/validators-form-password-the-same.helper";
import { TokenObject } from "../../../interfaces/token-object";

@Directive()
@Injectable()
export abstract class AbstractRegistrationConfirmAComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(ActivatedRoute) activatedRoute: ActivatedRoute;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(Store) configStore: Store<ApplicationConfig>;
  @OwInject(Router) router: Router;
  @OwInject(GuiService) guiService: GuiService;
  form: FormGroup;
  token: string;
  config: ApplicationConfig = null;
  subs = {
    config: null,
  };

  ngOnInit() {
    this.store
      .select(selectConfigOfApplication)
      .pipe(take(1))
      .subscribe(res => {
        this.config = res;
      });
  }

  subscribeConfig() {
    this.subs.config = this.configStore.select(selectConfigState).subscribe(config => {
      this.config = config.applicationConfig;
    });
  }

  subscribeRoutingParams() {
    this.activatedRoute.params.pipe(take(1)).subscribe(params => {
      if (params.hasOwnProperty("token")) {
        this.token = params["token"];
        this.clearForm();
      }
    });
  }

  clearForm() {
    this.form = this.fb.group(
      {
        token: this.fb.control(this.token, [Validators.required]),
        password: this.fb.control(null, [Validators.required]),
        passwordRepeat: this.fb.control(null, [Validators.required]),
      },
      {
        validator: checkPasswordTheSame,
      }
    );
  }

  registrationConfirm() {
    const data: RegistrationConfirmRequestData = {
      token: this.form.value.token,
      password: this.form.value.password,
    };

    this.registrationConfirmRequest(data).subscribe((resp: TokenObject) => {
      this.dispatchToken(resp);
      this.routeToPlayerChoose();
    });
  }

  registrationConfirmRequest(data: RegistrationConfirmRequestData) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };

    return this.apiService.post("registration/confirm", options);
  }

  dispatchToken(tokenObject: TokenObject) {
    this.store.dispatch(utilitySetToken({ token: tokenObject }));
  }

  routeToPlayerChoose() {
    this.guiService.isSplashShow.next(true);

    setTimeout(() => {
      this.router.navigate([APP_ROUTING_ABSOLUTE_PATH.PLAYER_CHOOSE]);
    });
  }
}

interface RegistrationConfirmRequestData {
  token: string;
  password: string;
}
