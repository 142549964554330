import { SceneConfig } from '../interfaces/scene-config.interface';
import { BASIC_SCENE_BASE_CONFIG } from '../scenes-basic/basic-scene.base-config';
import {clone} from 'ramda';
import {TREASURES_SCENE_WORLD_HEIGHT, TREASURES_SCENE_WORLD_WIDTH} from '../scenes-treasure/treasures.constants';
import {DISCOVER_SCENE_CONFIG_KEY} from '../config/custom-scenes.config';

export const DISCOVER_SCENE_CONFIG: SceneConfig | any = {
  ...clone(BASIC_SCENE_BASE_CONFIG),
  tiledStartOffset: {
    x: 0,
    y: 0
  },
  tiledJsonPrefix: null,
  backgroundImages: [
    {
      filename: 'bg2-l.jpg',
      key: 'map-treasures-l',
      origin: {
        x: 1,
        y: 0
      },
      displayWidth: TREASURES_SCENE_WORLD_WIDTH / 2,
      displayHeight: TREASURES_SCENE_WORLD_HEIGHT,
      x: 0,
      y: 0
    },
    {
      filename: 'bg2-r.jpg',
      key: 'map-treasures-r',
      origin: {
        x: 0,
        y: 0
      },
      displayWidth: TREASURES_SCENE_WORLD_WIDTH / 2,
      displayHeight: TREASURES_SCENE_WORLD_HEIGHT,
      x: 0,
      y: 0
    },
  ],
  mapDirectoryName: 'map-discover',
  tiledMapFileName: null,
  mapAtlasDirectory: 'map',
  islandGroupIdForBoard: 'C',
  boardStartOffset: {
    x: 0,
    y: 0
  },
  other: {
    gui_unlocks_button: 'discover_map',
    configKeyName: "discover"
  },
  configKeyName: "discover",
  tileMenuAtlasDirectory: 'tile-menu',
  tileTooltipConfig: {
    ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
    maxZoomScaleValue: 1.5
  },
  tileMenuConfig: {
    ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
    maxZoomScaleValue: 1.5
  },
  tileHoverConfig: {
    ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
    maxZoomScaleValue: 2
  }
};
