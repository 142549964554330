import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { from, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { IResult, UAParser } from 'ua-parser-js';

import { SectionItem } from "../../../core/interfaces/alert";
import { Currency } from "../../../core/interfaces/currency";
import { GlobalEvent } from "../../../core/interfaces/shared.interfaces";
import { AssetsService } from "../../../core/providers/assets.service";
import { CurrencyService } from "../../../core/providers/currency.service";
import { GlobalService } from "../../../core/providers/global.service";
import { SynchronizeTimeService } from "../../../core/providers/synchronize-time.service";
import { unsubscribeObject } from "../../../core/utility/unsubscribe-array";
import { gameFetchBoard, gameResetBoard } from "../../../store/game/actions";
import { selectGameBoardStatuses } from "../../../store/game/selectors";
import { AppState } from "../../../store/state";
import { ProductDetailsBalance } from "../../player/interfaces/product.interface";
import { PlayerService } from "../../player/providers/player.service";
import { ProductPlayerService } from "../../player/providers/product-player.service";
import { DialogService } from "../../shared/providers/dialog.service";
import { GAME_EVENTS } from "../constants";
import { MyGame } from "../game-engine/classes/core/MyGame";
import { MyScene } from "../game-engine/classes/core/MyScene";
import { ImagesAtlas } from "../game-engine/interfaces";
import { CenterMap } from "../game-engine/interfaces/shared";
import { MAIN_BOOT_SCENE } from "../game-engine/scenes-main/main.constants";
import { isAssetLoadedToPhaserCache } from "../game-engine/utils/game.helper";
import { ToHourPipe } from "../game-gui/directives/to-hour.pipe";
import { BoardService } from "./board.service";
import { BuildingsService } from "./buildings.service";
import { GuiService } from "./gui.service";
import { PhaserGameService } from "./phaser-game.service";
import { ProductionService } from "./production.service";

@Injectable({
  providedIn: "root",
})
export class GameService implements OnDestroy {
  progressLoading: number;
  deviceDetected: IResult = null;
  game: MyGame;
  openedMenu;
  isLowQuality = true; // !!localStorage.getItem('low-quality');

  debugUI;
  lastPlayerIslandId: number;
  centerOn: CenterMap;
  debugFolders: { [name: string]: any } = {};
  boardStatuses: { [key: number]: boolean } = {};

  subs = {
    gameBoardStatuses: null,
  };

  constructor(
    public assetsService: AssetsService,
    public buildingsService: BuildingsService,
    public dialogService: DialogService,
    public store: Store<AppState>,
    public router: Router,
    public globalService: GlobalService,
    public hoursPipe: ToHourPipe,
    public currencyService: CurrencyService,
    public playerService: PlayerService,
    public boardService: BoardService,
    public synchronizeTimeService: SynchronizeTimeService,
    public productionService: ProductionService,
    public productPlayerService: ProductPlayerService,
    public phaserGameService: PhaserGameService,
    public guiService: GuiService,
    public ngZone: NgZone
  ) {
    this.isLowQuality = !!localStorage.getItem("low-quality");
    this.deviceDetected = new UAParser(window.navigator.userAgent).getResult();

    this.subs.gameBoardStatuses = this.store.pipe(select(selectGameBoardStatuses)).subscribe(res => {
      const boardStatuses = {};
      for (const key of Object.keys(res)) {
        boardStatuses[key] = res[key].isReady;
      }
      this.boardStatuses = boardStatuses;
    });
  }

  addDebugFolder(folderName: string) {
    const existingFolder = this.getDebugFolder(folderName);
    if (existingFolder) {
      this.debugUI.removeFolder(existingFolder);
    }
    return (this.debugFolders[folderName] = this.debugUI.addFolder(folderName));
  }

  getDebugFolder(folderName: string) {
    return this.debugFolders[folderName];
  }

  initGame(config: any, debug?: boolean) {
    debug = this.globalService.isDevDomain ? debug : false;

    this.game = new MyGame(config);
    this.addEventsListenersToGame();

    if (debug) {
      this.game.debug = true;
      document.body.className = `${document.body.className} game-debug`;
    }

    return this.game;
  }

  private addEventsListenersToGame() {
    if (this.game) {
      this.game.canvas.addEventListener(
        "webglcontextlost",
        () => {
          this.game.destroy(true);
          location.reload();
        },
        false
      );
    }
  }

  prepareGameData(playerId: number, playerIslandId?: number) {
    this.fetchBoard(playerId, playerIslandId);
  }

  fetchBoard(playerId: number, playerIslandId: number) {
    this.store.dispatch(
      gameFetchBoard({
        playerIslandId: playerIslandId,
        playerId: playerId,
      })
    );
  }

  /**
   * Emit event on globalEmitter
   * @param event
   */
  emitGameEvent(event: GlobalEvent) {
    this.ngZone.run(() => {
      this.globalService.globalEvents.emit(event);
    });
  }

  /**
   * Load image and return promise with image key in game cache.
   * Promises are kept in object `key` property to avoid load two files with the same key name.
   * @param scene
   * @param assets[]
   * @returns {any}
   */
  loadGameImages(scene: MyScene, assets: string[]): Observable<any> {
    const promise = new Promise<void>((resolve, reject) => {
      if (assets.length > 0) {
        const promises = [];

        // iterate through assets array
        assets.forEach((assetPath) => {
          // if asset doesnt exist - skip
          if (!assetPath) {
            return void 0;
          }

          // make single promise for single image
          const assetPromise = new Promise((res, rej) => {
            scene.load.image(assetPath, assetPath);

            const onLoaded = (key) => {
              if (key === assetPath) {
                res(key);
              }
            };

            const onError = (file) => {
              if (file.key === assetPath) {
                rej(file);
              }
            };

            // Listen to file load events
            scene.load.on('filecomplete', onLoaded);
            scene.load.on('fileerror', onError);
          });

          promises.push(assetPromise);
        });

        // Start loading assets after adding them to queue. It MUST be triggered after adding everything to the queue, otherwise it will reset it after receiving another assets[]
        scene.load.start();

        // all settled(not .all()) because error on images is respected aswell
        Promise.allSettled(promises).then(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
    return from(promise);
  }

  safeSetSpriteTexture(sprite: Phaser.GameObjects.Sprite | Phaser.GameObjects.Image, textureUrl: string) {
    return new Promise((resolve, reject) => {
      if (isAssetLoadedToPhaserCache(textureUrl, this.game.textures.getTextureKeys())) {
        sprite.setTexture(textureUrl);
        resolve(textureUrl);
      } else {
        sprite.visible = false;
        this.loadGameImages(sprite.scene as MyScene, [textureUrl])
          .pipe(take(1))
          .subscribe(() => {
            try {
              sprite.setTexture(textureUrl);
              sprite.visible = true;
            } catch (error) {
              console.log(error);
              console.log(sprite);
            }
            resolve(textureUrl);
          });
      }
    });
  }

  /**
   * Get Phaser Cache JSON entries and search for a `textures` key.
   * If key exists it means that its texture atlas (can be multitexture if key container more than one element.
   * For now method handle only single texture atlas.
   * @return ImagesAtlas[]
   */
  getAtlasFromCache(): ImagesAtlas[] {
    return this.assetsService.getAtlasFromCache(this.game);
  }

  restartScene(playerId: number, scene: string = MAIN_BOOT_SCENE, playerIslandId?: number, customData?: any) {
    this.store.dispatch(gameResetBoard());
    this.prepareGameData(playerId, playerIslandId);
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: scene,
    });
  }

  async getDiscoverPlaceType(playerIslandId: number) {
    // const result = await this.boardService.getWorld(this.playerService.getActivePlayerId()).toPromise();
    // let placeType = null;
    // result.forEach(regionData => {
    //   if (!placeType) {
    //     const targetIsland = regionData.islands.find(island => island.player_island_id === playerIslandId);
    //     placeType = getDiscoverPlaceType(targetIsland);
    //   }
    // });
    // return placeType;
  }

  setLowQuality(clear?: boolean) {
    if (clear) {
      localStorage.removeItem("low-quality");
    } else {
      localStorage.setItem("low-quality", "on");
    }
    location.reload();
  }

  setDebugMode() {
    if (localStorage.getItem("debug-mode")) {
      this.clearDebugMode();
    } else {
      localStorage.setItem("debug-mode", "on");
      location.reload();
    }
  }

  clearDebugMode() {
    localStorage.removeItem("debug-mode");
    location.reload();
  }

  /**
   * Prepare array of all combined currencies and products with balances and icons.
   * If requireToHave is false return undefined for have property, to allow separate not having (false) from not needed to have.
   * @param currencies
   * @param products
   * @param requireToHave
   */
  combineCurrenciesAndProducts(currencies = [], products = [], requireToHave = true) {
    const returnItems: SectionItem[] = [];
    const currenciesDefs = this.currencyService.getCurrencyDefinitions(currencies) as Currency[];
    currenciesDefs.forEach(currency => {
      const currencyBalance = this.currencyService.getCurrencyBalance(
        currency,
        this.playerService.player.currency_balances
      );
      returnItems.push({
        name: currencyBalance.name,
        icon: currencyBalance.iconUrlBig,
        amount: currencyBalance.amount,
        have: requireToHave ? currencyBalance.have : undefined,
      });
    });

    products.forEach(productDef => {
      const product: ProductDetailsBalance = this.productionService.productsService.getProduct(productDef);
      returnItems.push({
        name: product.name,
        amount: product.amount,
        icon: product.icon_url,
        have: requireToHave ? product.have : undefined,
      });
    });

    return returnItems;
  }

  destroyAllScenes() {
    const scenes = this.game.scene.getScenes(false);
    scenes.forEach(scene => {
      this.game.scene.stop(scene.scene.key);
      (scene as MyScene).destroyScene();
      this.game.scene.remove(scene.scene.key);
    });
  }

  pauseGame() {
    if (this.game && this.game.currentScene) {
      this.game.currentScene.scene.pause();
    }
  }

  resumeGame() {
    if (this.game && this.game.currentScene) {
      this.game.currentScene.scene.resume();
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
