import { PromenadeRoadsClass } from './PromenadeRoads';

export class AddDecorativeBuildingsClass {
  generateBoardTiles(boardTiles) {
    const promenade = new PromenadeRoadsClass(boardTiles).generateRoads();
    return [
      ...promenade,
    ];
  }
}
