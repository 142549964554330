export const TREASURES_SCENE_WORLD_WIDTH = 7200;
export const TREASURES_SCENE_WORLD_HEIGHT = 4050;

export const TREASURES_MAIN_SCENE = 'TREASURES_MAIN_SCENE';

export const TREASURES_TILE_MENU_STYLES = {
  color: '#4d4d4d',
  fontSize: 28,
  fontFamily: 'Mikado',
  align: 'center',
  fontWeight: 'bold',
  boundsAlignH: 'bottom',
  wordWrapWidth: 200,
  wordWrap: true,
  boundsAlignV: 'bottom'
};

export const TREASURES_TILE_ARCHIVO = {
  ...TREASURES_TILE_MENU_STYLES,
  fontWeight: 'normal',
  fontFamily: 'Archivo Narrow',
};

