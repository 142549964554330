import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { take } from "rxjs/operators";

import { EventEmitterDialog } from "../../../core/interfaces/event-emitter-dialog.interface";
import { GlobalEvent } from "../../../core/interfaces/shared.interfaces";
import { Subs } from "../../../core/interfaces/subs.interface";
import { GlobalService } from "../../../core/providers/global.service";
import { EventEmitterDialogsService } from "../../../core/services/core/event-emitter-dialogs.service";
import { selectGameBoard, selectGameTileByTileId } from "../../../store/game/selectors";
import { GLOBAL_HANDLERS, HANDLING_METHOD } from "../constants/game-global-events-handlers.constants";
import { BoardTileCore } from "../game-engine/classes/core/BoardTileCore.class";
import { ChangeMapService } from "../game-ui/hud/hud-menu-buttons/services/core/change-map.service";

@Injectable({
  providedIn: "root",
})
export class GlobalGameEventsHandlerService {
  subs: Subs = {
    globalEmitter: null,
  };

  constructor(
    private eventEmitterDialogsService: EventEmitterDialogsService,
    private store: Store,
    private globalService: GlobalService,
    private changeMapService: ChangeMapService
  ) {}

  startHandling() {
    this.subs.globalEmitter = this.globalService.globalEvents.subscribe(event => {
      this.handling(event).then();
    });
  }

  async handling(event: GlobalEvent) {
    const handler = GLOBAL_HANDLERS[event.name];

    if (!handler) return;
    switch (handler.handling_method) {
      case HANDLING_METHOD.DIALOG_EMITTER: {
        this.handleByDialog(handler.returnData(event));
        break;
      }
      case HANDLING_METHOD.PHASER_OPEN_TILE_MENU: {
        this.handleByPhaserOpenTileMenu(handler.returnData(event));
        break;
      }
      case HANDLING_METHOD.PHASER_TILE_CLICK: {
        const tile = await this.store.pipe(select(selectGameTileByTileId(event.value)), take(1)).toPromise();
        this.handleByDialog(handler.returnData(event, tile));
        break;
      }
      case HANDLING_METHOD.STORE_DISPATCH: {
        this.handleByStoreDispatch(handler.returnData(event));
        break;
      }
      case HANDLING_METHOD.STORE_DISPATCH_MULTIPLE: {
        this.handleByStoreDispatchMultiple(handler.returnData(event));
        break;
      }
      case HANDLING_METHOD.MAP_CHANGER: {
        this.handleByChangeDialog(handler.returnData(event));
        break;
      }
    }
  }

  stopHandling() {
    this.subs.globalEmitter.unsubscribe();
  }

  handleByDialog(dialog: EventEmitterDialog) {
    this.eventEmitterDialogsService.emitter.emit(dialog);
  }

  handleByPhaserOpenTileMenu(element: BoardTileCore) {
    element.openTileMenu();
  }

  handleByStoreDispatch(element: any) {
    this.store.dispatch(element);
  }

  handleByChangeDialog(data: { config: {islandId?: number; configName?: string } }) {
    this.changeMapService.goToIsland(data?.config?.islandId, data?.config?.configName);
  }

  handleByStoreDispatchMultiple(elements: any[]) {
    elements.forEach(ele => {
      this.store.dispatch(ele);
    });
  }
}
