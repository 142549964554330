import { BoardTileCore } from "../core/BoardTileCore.class";
import {BOARD_TILE_ALPHA} from '../../../constants';
import {getTileHoverAnchor, getTileHoverTextureName} from '../../utils/board.helper';

export class BoardTile extends BoardTileCore {

  /**
   * Create tile with active state and.
   */
  createActiveTile() {
    const emptyTileIcon = this.tileData.empty_icon;
    const tileTextureFrame = emptyTileIcon ? emptyTileIcon : getTileHoverTextureName(this.tileData.tile_type);
    this.baseSprite = this.scene.add.image(0, 0, "map-atlas", tileTextureFrame);
    this.add(this.baseSprite);
    const tileAnchor = getTileHoverAnchor(this.tileData.tile_type);

    // issue #24726
    if (this.hasBuilding) {
      this.baseSprite.setScale(this.tileData.tile_type.width, this.tileData.tile_type.height);
    }

    this.baseSprite.setOrigin(tileAnchor.x, tileAnchor.y);
    this.baseSprite.alpha = BOARD_TILE_ALPHA;
    this.handleInput();
  }
}
