import { SceneConfig } from '../interfaces/scene-config.interface';
import { BASIC_SCENE_BASE_CONFIG } from '../scenes-basic/basic-scene.base-config';
import { clone } from 'ramda';

export const PROMENADE_SCENE_CONFIG: SceneConfig | any = {
  ...clone(BASIC_SCENE_BASE_CONFIG),
  tiledStartOffset: {
    x: 0,
    y: 0,
  },
  tiledJsonPrefix: null,
  backgroundImages: [
    {
      filename: "map-0-0.jpg",
      x: 0,
      y: 0,
      z: -1,
      key: "map-0-0",
      displayWidth: 4096,
      displayHeight: 3700,
    },
    {
      filename: "map-1-0.jpg",
      x: 4096,
      y: 0,
      z: -1,
      key: "map-1-0",
      displayWidth: 2304,
      displayHeight: 3700,
    },
  ],
  mapDirectoryName: "map-promenade",
  tiledMapFileName: null,
  islandGroupIdForBoard: "A",
  boardStartOffset: {
    x: 0,
    y: 0,
  },
  yAxisSign: 1,
  tileMenuAtlasDirectory: "tile-menu",
  cameraBounds: {
    x: 0,
    y: 0,
    width: 6400,
    height: 3700,
  },
  other: {
    sceneZoom: {
      max: 0.9,
      min: 0.4,
    },
    sceneSize: {
      width: 6400,
      height: 3700,
    },
    startZoom: 0.4,
    gui_unlocks_button: "promenade_map",
    configKeyName: "promenade",
  },
  mapAtlasDirectory: "map-promenade",
  cameraStartPosition: {
    x: 3400,
    y: 1000
  },
  configKeyName: "promenade",
  tileTooltipConfig: {
    ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
    maxZoomScaleValue: 1.5,
  },
  tileMenuConfig: {
    ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
    maxZoomScaleValue: 1.5,
  },
  tileHoverConfig: {
    ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
    maxZoomScaleValue: 1.5,
  },
};
