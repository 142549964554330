import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { select } from "@ngrx/store";
import { filter } from "rxjs/operators";
import {
  gameNewImportantMessageClosed,
  gameNewImportantMessageOpened,
  gameResetBoard,
} from "src/app/store/game/actions";

import { environment } from "../../../../../../../environments/environment";
import { orientationModes } from "../../../../../../core/consts/core/orientation-modes.enum";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { RegisterCustomFirebaseTokenService } from "../../../../../../core/services/core/register-firebase-token.service";
import { unsubscribeObject } from "../../../../../../core/utility/unsubscribe-array";
import { ImportantMessagesState } from "../../../../../../store/game/interfaces/ImportantMessages.state";
import { selectGameImportantMessages, selectGameReady } from "../../../../../../store/game/selectors";
import { handleMessageDetailsType } from "../../../../../game/game-ui/message/helpers/core/message.helper";
import { MessageEventFromDetails } from "../../../../../game/game-ui/message/interfaces/core/message-callback-from-details.interface";
import { MessageOpenDetailsData } from "../../../../../game/game-ui/message/interfaces/core/message-open-details-data.interface";
import { MessageService } from "../../../../../game/game-ui/message/services/core/message.service";
import { AbstractPlayGameComponent } from "../../../../abstract/core/abstract-play-game.component";

@Component({
  selector: "app-play-game",
  templateUrl: "./play-game.component.html",
})
export class PlayGameComponent extends AbstractPlayGameComponent implements OnInit, OnDestroy, AfterViewInit {
  @OwInject(MessageService) messagesService: MessageService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(RegisterCustomFirebaseTokenService) registerCustomFirebaseTokenService: RegisterCustomFirebaseTokenService;

  ngOnInit() {
    this.nativeAppService.setNativeAppOrientation(orientationModes.Landscape);
    this.setDynamicsComponents();
    this.gameService.guiService.isSplashShow.next(true);
    this.updateUserOnce();
    this.updatePlayerOnce();
    this.globalGameEventsHandlerService.startHandling();
    this.subs.importantMessages = this.store
      .pipe(
        select(selectGameImportantMessages),
        filter(state => state.messages.length && !state.importantMessageDisplayed),
        filter(() => !this.guiService.isLockOpenDialog)
      )
      .subscribe((importantMessages: ImportantMessagesState) => {
        const message = importantMessages.messages[0];
        this.store.dispatch(gameNewImportantMessageOpened({ opened: true }));
        const messageType = handleMessageDetailsType(message);
        const eventDialog = this.messagesService.handleMessageDetailsComponent(messageType);

        const messageOpenDetailsData: MessageOpenDetailsData = {
          message: message,
          fromList: false,
        };

        this.eventEmitterDialogsService.emitter.emit({
          name: eventDialog,
          config: {
            data: messageOpenDetailsData,
            disableClose: true,
          },
          callback: (event: MessageEventFromDetails) => {
            this.store.dispatch(gameNewImportantMessageClosed({ message }));
          },
        });
      });
    this.gameReady$ = this.store.pipe(select(selectGameReady));
  }

  ngAfterViewInit() {
    this.setDynamicsComponentsOthers();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
    clearTimeout(this.inactivityTimeout);
    this.store.dispatch(gameResetBoard());
    this.nativeAppService.setNativeAppOrientation(orientationModes.Auto);
    this.globalGameEventsHandlerService.stopHandling();
  }
}
