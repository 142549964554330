import { EventDialog } from "../../../../../../core/interfaces/event-dialog.interface";
import { QuestionComponent } from "../../../business/basic/custom/dialogs/question/question-component";
import { QuestionListComponent } from "../../../business/basic/custom/dialogs/question-list/question-list.component";
import { QuestionMoreInfoComponent } from "../../basic/core/dialogs/question-more-info/question-more-info.component";
import { EVENT_DIALOGS_NAMES_DAILY_QUESTIONS } from "./event-names.const";

export const EVENT_DIALOGS_DAILY_QUESTIONS: EventDialog = {
  [EVENT_DIALOGS_NAMES_DAILY_QUESTIONS.QUESTION]: QuestionComponent,
  [EVENT_DIALOGS_NAMES_DAILY_QUESTIONS.QUESTION_LIST]: QuestionListComponent,
  [EVENT_DIALOGS_NAMES_DAILY_QUESTIONS.QUESTION_MORE_INFO]: QuestionMoreInfoComponent,
};
