import { Component, OnInit } from "@angular/core";
import {ActivatedRoute, Router} from '@angular/router';

import { STORAGE_DATA } from "../../../../../../core/consts/core/storage/storage-data.const";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { utilityRemoveToken } from "../../../../../../store/utility/actions";
import { AbstractTempTokenExchangeComponent } from "../../../../abstract/abstract-temp-token-exchange.component";
import {selectUtilityToken} from '../../../../../../store/utility/selectors';

@Component({
  selector: "app-temp-token-exchange",
  template: "",
})
export class TempTokenExchangeComponent extends AbstractTempTokenExchangeComponent implements OnInit {
  @OwInject(ActivatedRoute) route: ActivatedRoute;
  @OwInject(Router) router: Router;

  ngOnInit() {
    // check if there is a token
    this.store.select(selectUtilityToken).subscribe(res => {
      // if there is
      if (res?.tokenObject?.token) {
        // redirect to login page
        this.redirectLogin();
      } else {
        // otherwise just follow normal flow; someone is here by accident(back btn/gesture)
        this.initializeExchanging();
      }
    })
  }

  initializeExchanging() {
    if (this.route.snapshot.queryParams["isEphemeralSession"]) {
      const sessionStorageDef = STORAGE_DATA.storageDefinitions.find(x => x.type === "session_storage");
      if (sessionStorageDef.supported) {
        sessionStorage.setItem("isEphemeralSession", "true");
        STORAGE_DATA.initialize();
      }
    }

    this.store.dispatch(utilityRemoveToken());
    this.subscribeRoutingParams();
  }
}
