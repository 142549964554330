import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

import { DialogService } from "../../../../../../../shared/providers/dialog.service";

@Component({
  selector: "app-button-close",
  templateUrl: "close-button.component.html",
  styleUrl: "close-button.component.scss",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgClass],
})
export class CloseButtonComponent {
  @Input() preventDefault: boolean;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private dialogService: DialogService) {}

  click() {
    this.clicked.emit(true);

    if (!this.preventDefault) {
      this.dialogService.closeAll();
    }
  }
}
