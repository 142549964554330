import { Component, OnInit } from "@angular/core";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { AssetsService } from "../../../../../../../../core/providers/assets.service";
import { ParametersService } from "../../../../../../../../core/providers/parameters.service";
import { AbstractStockBase } from "../../../../abstract/abstract-stock-base.component";
import { STOCK_VIEW } from "../../../consts/stock-view.const";

@Component({
  selector: "m-ui-parameter",
  templateUrl: "../stock.html",
})
export class MUiParameterComponent extends AbstractStockBase implements OnInit {
  @OwInject(AssetsService) assetsService: AssetsService;
  @OwInject(ParametersService) parametersService: ParametersService;
  defaultStockView = STOCK_VIEW.A;

  ngOnInit() {
    this.setDefaultStockView();
    this.parseItem();
    this.setIconUrl();
  }

  setIconUrl() {
    this.item.iconUrl = this.assetsService.getAssetPath(`parameters/basic/${this.item.icon}`, `parameter icon normal obj: ${JSON.stringify(this.item)} inside ui-parameter.component.ts`);
    this.item.iconUrlBig = this.assetsService.getAssetPath(`parameters/big/${this.item.icon}`, `parameter icon big obj: ${JSON.stringify(this.item)} inside ui-parameter.component.ts`);
  }

  parseItem() {
    this.item = { ...this.item, ...this.parametersService.getParameterDefinition(this.item.parameter_id) };
    super.parseItem();
  }
}
