import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { DialogService } from "../../../../../../../shared/providers/dialog.service";

@Component({
  selector: "app-button-back",
  templateUrl: "button-back.component.html",
  styleUrl: "button-back.component.scss",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgClass],
})
export class ButtonBackComponent {
  @Input() preventDefault: boolean;
  @Input() closeValue: any;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private dialogService: DialogService,
    private matDialogRef: MatDialogRef<any>
  ) {}
  click() {
    this.clicked.emit(true);

    if (!this.preventDefault) {
      this.matDialogRef.close(this.closeValue);
    }
  }
}
