<!-- LOGO -->
<div class="logo"></div>

<loading></loading>
<div class="wrapper">
    <div class="container loading-opacity">
        <p class="title">{{ "auth.forgot-password.title" | myTranslate }}:</p>

        <ng-container *ngIf="formSendSuccess; else formTpl">
            <!-- SEND SUCCESS INFO -->
            <p class="description" [innerHTML]="'auth.forgot-password.send-success-info' | myTranslate"></p>
        </ng-container>
        <ng-template #formTpl>
            <form [formGroup]="form">
                <!-- EMAIL FORM -->
                <div class="control">
                    <div class="label-flex">
                        <label>{{ ("auth.login-email" | myTranslate) }}:</label>
                    </div>
                    <input class="control-input no-ow-input-directive" formControlName="email" type="text" />
                </div>
            </form>
        </ng-template>

        <div class="buttons">
            <button class="base primary" [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.LOGIN">
                {{ "auth.forgot-password.button-back" | myTranslate }}
            </button>

            <button class="base primary" (click)="onFormSubmit()" [disabled]="form.invalid" *ngIf="!formSendSuccess">
                {{ "auth.forgot-password.button-send" | myTranslate }}
            </button>
        </div>
    </div>
    <a class="contact" [href]="'mailto:' + config?.common?.contact_email">{{ config?.common?.contact_email ?? ''}}</a>
</div>
<button class="base primary explore-game"
        *ngIf="config.landing_page.enabled"
        [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.LANDING">
    {{ "auth.login.know-your-mission" | myTranslate }}
</button>

<div class="copyright-container" *ngIf="config.default_page.copyright_text">
    <p [innerHTML]="config.default_page.copyright_text"></p>
</div>
