export const CONFIG = {
  apiKey: "AIzaSyDf6FSKBScFzpdsPwAL09axVzuYG0qM_po",
  authDomain: "grywalizacja-c5cc1.firebaseapp.com",
  projectId: "grywalizacja-c5cc1",
  storageBucket: "grywalizacja-c5cc1.appspot.com",
  messagingSenderId: "450735396023",
  appId: "1:450735396023:web:abca043d5368942a17a932",
};

export const VAPIDKEY = "BHG8WruGfPLp7zmR_ag6_2Kk3Q9krk2h17GMgqDb_hwbWmPGPBRyn47j-GQus4oZu-0ruBf3TrNddFEALJc0J9o";
