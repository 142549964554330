import { Directive, HostBinding, Injectable, Input, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { GlobalEvent } from "../../../../../../core/interfaces/shared.interfaces";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { selectConfigHudPosition } from "../../../../../../store/config/selectors";
import { AppState } from "../../../../../../store/state";
import {
  selectUtilityHasMissionsToCollect,
  selectUtilityHasNewMessagesToRead,
  selectUtilityNewProductInStorage,
} from "../../../../../../store/utility/selectors";
import { Player } from "../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { PreviewPlayerService } from "../../../../game-gui/providers/preview-player.service";
import { HudMenuMoreComponent } from "../../base/core/dialogs/hud-menu-more/hud-menu-more.component";
import { MENU_BUTTONS_ORDER_DISPLAY } from "../../hud-menu-buttons/const/core/menu-buttons-order-display.const";
import {
  MappedMenuButtonConfig,
  MenuButtonConfig,
} from "../../hud-menu-buttons/interfaces/core/menu-buttonts-config.interface";
import { MenuButtonsConfigService } from "../../hud-menu-buttons/services/core/menu-buttons-config.service";
import {selectGameCurrentScene} from '../../../../../../store/game/selectors';
import {selectPlayer} from '../../../../../../store/player/selectors';

@Directive()
@Injectable()
export abstract class AbstractHudMenuComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(MenuButtonsConfigService) menuButtonsConfigService: MenuButtonsConfigService;
  @OwInject(PreviewPlayerService) previewPlayerService: PreviewPlayerService;
  @OwInject(GlobalService) globalService: GlobalService;
  @HostBinding("class") hostClass: "left" | "top" = null;

  @Input() isDialog: boolean;
  @Input() data: {
    player: Player;
  };

  hasMissionsToCollect: Observable<boolean>;
  newProductsInStorage: Observable<any[]>;
  hasNewMessagesToRead: Observable<boolean>;

  menuButtons: MappedMenuButtonConfig[] = [];
  MENU_BUTTONS_ORDER_DISPLAY = MENU_BUTTONS_ORDER_DISPLAY;
  otherButtonsIsActive = false;
  groups = [];
  menuMoreWasClicked = false;

  subs: { scene: any; player: any; globalEmitter: any } = {
    scene: null,
    player: null,
    globalEmitter: null,
  };
  guiUnlocks: {[key: string]: boolean} = {};

  sceneConfig: { gui_unlocks_button: string; configKeyName: string; other: any };

  ngOnInit() {
    // set correct position of resources
    this.store
      .select(selectConfigHudPosition)
      .pipe(take(1))
      .subscribe(res => {
        this.hostClass = res.element_positions.buttons;
      });

    this.subs.scene = this.store.select(selectGameCurrentScene).subscribe(res => {
      if (res) {
        this.setMenu();
      }
    })

    this.subs.player = this.store.select(selectPlayer).subscribe(res => {
      // if gui_unlocks is different(player changed, rule for "has_building" is fulfilled, then refresh menu
      if (res && JSON.stringify(this.guiUnlocks) !== JSON.stringify(res.gui_unlocks)) {
        this.guiUnlocks = res.gui_unlocks;
        this.setMenu()
      }
    })
  }

  afterConstructor() {
    this.hasMissionsToCollect = this.store.pipe(select(selectUtilityHasMissionsToCollect));
    this.newProductsInStorage = this.store.pipe(select(selectUtilityNewProductInStorage));
    this.hasNewMessagesToRead = this.store.pipe(select(selectUtilityHasNewMessagesToRead));
  }

  async handleGameEvents(event: GlobalEvent) {
    if (event?.name === "SCENE_CONFIG_CHANGE") {
      this.sceneConfig = {
        gui_unlocks_button: event.value.gui_unlocks_button, // this is the same as it is in store
        configKeyName: event.value.configKeyName,
        other: event.value.other,
      };
      this.setMenu();
    }
  }

  setMenu() {
    let buttons = this.menuButtonsConfigService.getMenuLeft({
      gui_unlocks: this.guiUnlocks
    });

    buttons = buttons.filter(button => {
      return button.onlyIsActiveMe ? this.playerService.isActiveMe : true;
    });

    this.menuButtons = structuredClone(buttons);
    this.setNotification();
    this.otherButtonsIsActive = this.menuButtons.length > 3 && !this.isDialog;
  }

  setNotification() {
    this.menuButtons.forEach(button => {
      switch (button.name) {
        case "missions":
          button["customNotification"] = {
            type: "async",
            observable: this.hasMissionsToCollect,
          };
          break;

        case "messages":
          button["customNotification"] = {
            type: "async",
            observable: this.hasNewMessagesToRead,
          };
          break;

        case "warehouse":
          button["customNotification"] = {
            type: "async",
            observable: this.newProductsInStorage.pipe(map(arr => arr.length)),
          };
          break;
      }
    });
  }

  toggleGroupButtons(button: MappedMenuButtonConfig) {
    button.group_buttons.forEach(groupBtn => (groupBtn.isVisible = !groupBtn.isVisible));
  }

  handleMenuButtonEvent(button: MenuButtonConfig) {
    // preview player mode
    if (!this.playerService.isActiveMe && button.show_only_active_me) {
      return;
    }

    if (button.type === "group") {
      this.toggleGroupButtons(button);
      return;
    }

    this.menuButtonsConfigService.triggerCorrectEventButtonHandler(button);
  }

  handleMenuMoreEvent() {
    // if menu more is currently active then clicking on this btn should close every active modal
    if (this.dialogService.dialog.openDialogs.length > 0) {
      this.dialogService.dialog.closeAll();
      return;
    }

    this.menuMoreWasClicked = true;

    this.dialogService.open(HudMenuMoreComponent, {}, () => {
      this.menuMoreWasClicked = false;
    });
  }
}
