import {PROMENADE_MAIN_SCENE} from '../scenes-promenade/promenade.constants';
import {PromenadeMainScene} from '../scenes-promenade/scenes/PromenadeMain.scene';
import {TREASURES_MAIN_SCENE} from '../scenes-treasure/treasures.constants';
import {TreasuresMainScene} from '../scenes-treasure/scenes/TreasuresMain.scene';
import {PROMENADE_SCENE_CONFIG} from '../scenes-promenade/promenade-scene.config';
import {TREASURES_SCENE_CONFIG} from '../scenes-treasure/treasures-scene.config';
import {DISCOVER_SCENE_CONFIG} from '../scenes-discover/discover-scene.config';

// this has to be exactly the same as in scene
export const PROMENADE_SCENE_CONFIG_KEY = "promenade";
export const TREASURES_SCENE_CONFIG_KEY = "treasures";
export const DISCOVER_SCENE_CONFIG_KEY = "discover";

// user in basic-scene.core.config to add scene configurations
export const ScenesMapConfigs = {
  default: PROMENADE_SCENE_CONFIG,
  [PROMENADE_SCENE_CONFIG_KEY]: PROMENADE_SCENE_CONFIG,
  [TREASURES_SCENE_CONFIG_KEY]: TREASURES_SCENE_CONFIG,
  [DISCOVER_SCENE_CONFIG_KEY]: DISCOVER_SCENE_CONFIG
}

// user to add scenes directly to phaser
export const CustomScenesDeclarationsConfig = [
  {
    name: PROMENADE_MAIN_SCENE,
    scene: PromenadeMainScene
  },
  {
    name: TREASURES_MAIN_SCENE,
    scene: TreasuresMainScene
  }
]

