import { ComponentFactoryResolver, Directive, Injectable, OnDestroy, ViewChild, ViewContainerRef } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { selectPlayer } from "src/app/store/player/selectors";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { setDynamicsComponents } from "../../../../../../core/helpers/dynamic-component.helper";
import { Subs } from "../../../../../../core/interfaces/subs.interface";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { unsubscribeObject } from "../../../../../../core/utility/unsubscribe-array";
import { AppState } from "../../../../../../store/state";
import { selectUser } from "../../../../../../store/user/selectors";
import { Player } from "../../../../../player/interfaces/player";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { User } from "../../../../../user/interfaces/user";
import { BoardService } from "../../../../services/board.service";
import { BuildingsService } from "../../../../services/buildings.service";
import { GameService } from "../../../../services/game.service";
import { ChestService } from "../../../chest/services/core/chest.service";
import { CORE_DYNAMIC_COMPONENTS } from "../../const/core/dynamic-components/hud/dynamic-components.const";
import { CUSTOM_DYNAMIC_COMPONENTS } from "../../const/custom/dynamic-components/hud/dynamic-components.const";

@Directive()
@Injectable()
export abstract class AbstractHudComponent extends AbstractInjectBaseComponent implements OnDestroy {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(ChestService) chestService: ChestService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(BoardService) boardService: BoardService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(ComponentFactoryResolver) componentFactoryResolver: ComponentFactoryResolver;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @ViewChild("hudMenuTpl", { static: true, read: ViewContainerRef }) hudMenuTpl: ViewContainerRef;
  @ViewChild("hudParametersTpl", { static: true, read: ViewContainerRef }) hudParametersTpl: ViewContainerRef;
  @ViewChild("hudResourcesTpl", { static: true, read: ViewContainerRef }) hudResourcesTpl: ViewContainerRef;
  @ViewChild("dynamicsComponentTpl", { static: true, read: ViewContainerRef }) dynamicsComponentTpl: ViewContainerRef;

  subs: Subs = {
    player: null,
    user: null,
    globalEmitter: null,
  };

  player: Player;
  user: User;

  setDynamicsComponents() {
    setDynamicsComponents.bind(this)({
      coreDynamicComponents: CORE_DYNAMIC_COMPONENTS,
      customDynamicComponents: CUSTOM_DYNAMIC_COMPONENTS,
    });
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(selectPlayer)).subscribe(player => {
      this.player = player;
      this.chestService.checkChestsAndOpen(player);
    });
  }

  subscribeUser() {
    this.subs.user = this.store.pipe(select(selectUser)).subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    unsubscribeObject(this.subs);
  }
}
