import { Injectable, Type } from "@angular/core";
import { Router } from "@angular/router";
import moment from "moment";
import { Moment } from "moment";
import * as R from "ramda";
import { forkJoin, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { EVENTS } from "../../../../../../core/consts/core/events";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { DeviceService } from "../../../../../../core/providers/device.service";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { OwDate } from "../../../../../../core/utility/ow-date.class";
import { AssetPipe } from "../../../../../shared/pipes/asset.pipe";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { EVENT_DIALOGS_NAMES_MESSAGE } from "../../../message/consts/core/event-dialogs/event-names.const";
import { MESSAGE_TYPE_ID } from "../../../message/consts/core/message-type.const";
import { ApiQaService } from "../../../qa/api/core/services/api-qa.service";
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from "../../../rankings/consts/custom/event-dialogs/event-names.const";
import { BusinessRanksService } from "../../../rankings/services/custom/business-ranks.service";
import { GetPlayerTasksRequest } from "../../api/custom/interfaces/get-player-tasks-request.interface";
import { ApiBusinessService } from "../../api/custom/services/api-business.service";
import { ApiBusinessWeekly } from "../../api/custom/services/api-business-weekly";
import { QuestionListComponent } from "../../basic/custom/dialogs/question-list/question-list.component";
import { TASK_TYPE } from "../../consts/custom/task-type.const";
import { prepareCardTask } from "../../helpers/custom";
import { CardTask } from "../../interfaces/custom/card-task.interface";
import { BusinessService } from "../../services/custom/business.service";
import { AbstractBasicActionsComponent } from "./abstract-basic-actions.component";

@Injectable()
export abstract class AbstractBusinessCards extends AbstractInjectBaseComponent {
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(ApiBusinessWeekly) apiBusinessWeekly: ApiBusinessWeekly;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(Router) router: Router;
  @OwInject(AssetPipe) assetPipe: AssetPipe;
  @OwInject(ApiQaService) apiQaService: ApiQaService;
  @OwInject(DeviceService) deviceService: DeviceService;
  @OwInject(BusinessService) businessService: BusinessService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(BusinessRanksService) businessRanksService: BusinessRanksService;

  abstract basicActionsComponent: Type<AbstractBasicActionsComponent>;
  TASK_TYPE = TASK_TYPE;

  subs = {
    global: null,
  };
  visible = true;
  hideCards: boolean;
  cardTasks: CardTask[];
  cardTasksEvents: CardTask[];
  cardTasksInteractive: CardTask[];
  allCardTasks: Array<CardTask>;
  paginationDate: {
    current: Moment;
    start?: Moment;
    end?: Moment;
    allowNext?: boolean;
    allowPrev?: boolean;
  };
  customLoading: boolean;
  startDate: {
    month: number;
    year: number;
  };
  endDate: {
    month: number;
    year: number;
  };
  _momentDate: Moment = this.createMomentIsoDate();

  get momentDate(): Moment {
    return this._momentDate;
  }

  get mockTaskNotFinished() {
    return this.businessService.mockTaskNotFinished;
  }

  set momentDate(momentDate: Moment) {
    this._momentDate = momentDate;
    this.setPagination();
  }

  subscribeGlobalEvents() {
    this.subs.global = this.globalService.globalEvents.subscribe(event => {
      switch (event.name) {
        case EVENTS.GUI.RELOAD_CARDS:
          this.getTasks();
          break;

        // case CUSTOM_EVENTS.GUI.BUSINESS.SHOW:
        //   this.visible = true;
        //   break;
        //
        // case CUSTOM_EVENTS.GUI.BUSINESS.HIDE:
        //   this.visible = false;
        //   break;
      }
    });
    this.businessService.getMessagesMockUp();
  }

  getTasks() {
    this.customLoading = true;
    this.setPagination();
    forkJoin([this.getTasksBasic()]).subscribe(() => {
      this.setAllCardTasks();
      this.customLoading = false;
      this.cardTasks.forEach(card => {
        return this.businessRanksService.isRankInBusinessRank(card);
      });
    });
  }

  setAllCardTasks() {
    this.allCardTasks = R.sortWith([R.ascend(R.prop("card_position"))], [...this.cardTasks]);
  }

  getTasksBasic() {
    const getPlayerTasksRequest: GetPlayerTasksRequest = {
      month: this.momentDate.month() + 1,
      year: this.momentDate.year(),
    };

    return this.apiBusinessService.playerTasks(getPlayerTasksRequest).pipe(
      tap((resp: CardTask[]) => {
        this.clearCardTasks();
        this.cardTasks = prepareCardTask(resp);
      }),
      catchError(errResp => {
        this.customLoading = false;
        console.error(errResp);
        this.clearCardTasks();
        return of();
      })
    );
  }

  clearCardTasks() {
    this.cardTasks = [];
  }

  clearCardTasksEvents() {
    this.cardTasksEvents = [];
  }

  clearCardTasksInteractive() {
    this.cardTasksInteractive = [];
  }

  toggleVisibleCards() {
    this.hideCards = !this.hideCards;
  }

  setPagination() {
    this.paginationDate = {
      current: this.momentDate,
      start: this.createMomentIsoDate({ year: this.startDate.year, month: this.startDate.month }),
      end: this.createMomentIsoDate({ year: this.endDate.year, month: this.endDate.month }),
    };

    this.paginationDate.allowNext = this.paginationDate.current.isBefore(this.paginationDate.end, "month");
    this.paginationDate.allowPrev = this.paginationDate.current.isAfter(this.paginationDate.start, "month");
  }

  next() {
    this.momentDate.add({ month: 1 });
    this.getTasks();
  }

  prev() {
    this.momentDate.subtract({ month: 1 });
    this.getTasks();
  }

  openRankList() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_LIST,
      config: {
        data: {
          location: 2,
          name: "RANKINGI BIZNESOWE",
        },
      },
    });
  }

  openRankListGames() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_LIST,
      config: {
        data: {
          location: 1,
          name: "RANKINGI ZE ŚWIATA GRY",
        },
      },
    });
  }

  openQaList() {
    const data = {
      type: MESSAGE_TYPE_ID.QA,
      title: " ",
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES,
      config: {
        data,
      },
    });
  }

  openDailyQuestions() {
    this.dialogService.open(QuestionListComponent);
  }

  createMomentIsoDate({ year, month }: { year?: number; month?: number } = {}) {
    if (month !== null && month !== undefined && year !== null && year !== undefined) {
      return moment([year, month]);
    }

    const date = new OwDate();
    year = date.year;
    month = date.month - 1;
    return moment([year, month]);
  }

  openPhotoGallery() {
    this.router.navigate(["/auth/gallery"]);
  }
}
