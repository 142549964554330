import {BoardTileState} from '../../../../store/game/interfaces/board-tile.state';
import {TileCleaners, TileCreationTriggers, TileCreators, TileReposition} from '../config/custom-board.config';
import {BoardTile} from '../classes/custom/BoardTile.class';

export function checkFilesToBeHidden(updatedTile: BoardTileState) {
  // get generateFnName
  const triggerCreationName = TileCreationTriggers[updatedTile?.player_building?.building_id].generateFnName;

  // return a list with the items to be removed
  const tilesToBeRemoved: BoardTile[] = TileCleaners[triggerCreationName](this.boardTiles)
  if (tilesToBeRemoved.length > 0) {
    for (let item of tilesToBeRemoved) {
      // we're making it invisibile
      item.setZ(-20);
    }
  }
}

export function checkDecorativeTilesToBeRendered(updatedTile: BoardTileState, boardTiles: BoardTileState[]) {
  // if the building changed and has "visible status" (0 - under construction)
  if (TileCreationTriggers[updatedTile?.player_building?.building_id] && updatedTile?.player_building?.status === 1) {
    // get generateFnName
    const triggerCreationName = TileCreationTriggers[updatedTile?.player_building?.building_id].generateFnName;

    // then generate new one
    const decorativeTiles = TileCreators[triggerCreationName](boardTiles);

    return decorativeTiles ?? [];
  }

  return [];
}


export function repositionChangeTilePosition(tile: BoardTileState) {
  if (!tile || !tile.player_building) {
    return tile;
  }
  const position = TileReposition[`${tile.player_building.group}-${tile.player_building.level}`];
  if (position) {
    tile.x_pos = position.x;
    tile.y_pos = position.y;
  }
  return tile;
}
