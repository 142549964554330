<loading></loading>

<ng-container *ngIf="!isLoading && groupMissions?.length">
  <div [class.all-stars-collected]="allStarsCollected || noMissionsForActiveGroup" class="stars loading-opacity">
    <ng-container *ngFor="let groupMission of groupMissions">
      <div
        [class.star-full]="groupMission.is_reward_collected || allStarsCollected"
        [class.star-half]="activeGroupMission?.mission_group_id === groupMission.mission_group_id"
        [matTooltip]="!groupMission.is_reward_collected && activeGroupMission?.mission_group_id !== groupMission.mission_group_id
          ? 'Awansuj na poprzednie gwiazdki, aby móc rozpocząć zdobywanie tego statusu'
          : ''"
        class="star"
        matTooltipPosition="above"
      ></div>
    </ng-container>
  </div>

  <ng-container *ngIf="activeGroupMission">
    <div class="title loading-opacity">
      <span>{{ activeGroupMission.name }}</span>
      Nowe opcje rozwoju po awansie na kolejny status
    </div>

    <div
      *ngIf="!noMissionsForActiveGroup"
      [innerHtml]="activeGroupMission.description"
      class="mission-description loading-opacity"
    ></div>

    <div *ngIf="activeGroupMissionDetails" class="progress loading-opacity">
      <div class="tabs">
        <div
          *ngFor="let mission of activeGroupMissionDetails.missions;
            let last = last"
          [class.active]="mission.title === activeMission.title"
          [class.complete]="mission.is_collected"
          class="tab"
        >
          <div
            (click)="changeMission(mission)"
            class="tab-container"

          >
            <span>{{ mission.title }}</span>
          </div>
          <div *ngIf="!last" class="tab-separator">
            <div class="separator-arrow arrow-down"></div>
            <div class="separator-arrow arrow-up"></div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="activeMissionDetails">
      <div class="missions loading-opacity">
        <div
          [innerHtml]="activeMissionDetails.description"
          class="missions-info"
          owEllipsis
        ></div>
        <div class="missions-stages">
          <div
            *ngFor="let condition of activeMissionDetails.conditions"
            [class.text-bold]="condition.completed_at"
            class="stage"
          >
            <div [class.stage-box-active]="condition.completed_at"
                 class="stage-box">
              <div class="box"></div>
              <span>{{condition.name}}</span>
            </div>
            <ng-container [ngSwitch]="condition.target_type">
              <!-- INTEGER -->
              <ng-container *ngSwitchCase="MISSION_CONDITION_VALUE_TYPE.INTEGER">
                <span>{{ condition.value }} / {{ condition.target }}</span>
              </ng-container>

              <!-- PERCENTAGE -->
              <ng-container *ngSwitchCase="MISSION_CONDITION_VALUE_TYPE.PERCENTAGE">
                <span>{{ condition.value | percent:'1.2-2'}} / {{ condition.target | percent:'1.2-2' }}</span>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- BOTTOM BAR -->
      <div class="bottom-bar">
        <!-- SHOW ON MAP -->
        <button
          (click)="showOnMap(activeMissionDetails['center_map'])"
          *ngIf="activeMissionDetails['center_map_on_tile_id']"
          class="c-base c-primary"
        >
          {{ 'missions.show-on-map' | myTranslate }}
        </button>

        <!-- COLLECT -->
        <div class="bar-button base">
          <button
            (click)="completeStage()"
            [disabled]="!areConditionsComplete"
            class="base secondary"
          >
            Zalicz etap
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="!isLoading && noMissionsForActiveGroup">
  <div class="success">
    <p>Pędzisz jak burza!</p>

    Daj mi chwilę na zastanowienie i opracowanie dalszego planu działania.
    <br>
    Pośpiech przecież nie jest tu wskazany, w końcu to rajska wyspa.
    <br>
    Odsapnij, a ja lada moment rzucę Ci kolejne wyzwania.
  </div>
</ng-container>

<ng-container *ngIf="!isLoading && allStarsCollected">
  <div class="success">
    <p>BRAWO!</p>

    Ten prestiż, ten szyk, styl i bogactwo!
    <br>
    Twój kurort to perełka wśród kurortów! Twoja wyspa osiągnęła 5-gwiazdkowy,
    <br>
    najwyższy certyfikat i nie ma sobie równych!
  </div>
</ng-container>
