import { Directive, HostBinding, HostListener, Injectable } from "@angular/core";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import * as R from "ramda";
import { combineLatest } from "rxjs";
import { filter, take } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { CurrencyBalance, CurrencyDefinition } from "../../../../../../core/interfaces/currency";
import { CurrencyService } from "../../../../../../core/providers/currency.service";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { selectConfigHudPosition } from "../../../../../../store/config/selectors";
import { selectGameCurrentScene } from "../../../../../../store/game/selectors";
import { selectPlayer } from "../../../../../../store/player/selectors";
import { selectPrimaryCurrencies } from "../../../../../../store/primary/selectors";
import { AppState } from "../../../../../../store/state";
import { selectUser } from "../../../../../../store/user/selectors";
import { Player } from "../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { User } from "../../../../../user/interfaces/user";
import { GameService } from "../../../../services/game.service";
import { EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY } from "../../../transaction-history/consts/core/event-dialogs/event-names.const";
import { GuiItem, HudGuiItem } from "../../interfaces/core/gui-item.interface";

@Directive()
@Injectable()
export abstract class AbstractHudResourcesComponent extends AbstractInjectBaseComponent {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @HostBinding("class") hostClass: "left" | "top" | "right" = null;
  @HostListener("window:resize", ["$event"]) resizeEvent(event: Event) {
    this.isMobileMediaQuery = window.innerWidth <= 1023;
  }

  currencyDefinitions: CurrencyDefinition[] = [];
  player: Player;
  user: User;
  currentIslandId: number = -1;

  guiItems: HudGuiItem[] = [];
  isMobileMediaQuery = false;

  subs = {
    user: null,
    combine: null,
  };

  PLACEHOLDERS = [
    {
      placeholder: "%balance%",
      getValue: item => (item ? item.balance : 0),
    },
    {
      placeholder: "%storage_capacity%",
      getValue: product => (product ? product.storage_capacity : 0),
    },
    {
      placeholder: "%daily_production%",
      getValue: product => (product ? product.daily_production : 0),
    },
  ];

  setParameters() {
    const currency$ = this.store.pipe(
      select(selectPrimaryCurrencies),
      filter(x => x.length !== 0),
      take(1)
    );
    const player$ = this.store.pipe(select(selectPlayer));
    const currentScene$ = this.store.pipe(select(selectGameCurrentScene));
    const user$ = this.store.pipe(select(selectUser));

    // set correct position of resources
    this.store
      .pipe(select(selectConfigHudPosition))
      .pipe(take(1))
      .subscribe(res => {
        this.hostClass = res.element_positions.resource;
      });

    // if currency, player or scene changes...
    this.subs.combine = combineLatest([currency$, player$, currentScene$, user$]).subscribe(
      ([currencies, player, currentIslandId, user]) => {
        // get current definitions and balances
        this.player = player;
        this.user = user;
        this.currencyDefinitions = currencies as CurrencyDefinition[];
        this.currentIslandId = parseInt(currentIslandId);

        // iterate through gui_items to update balance and check visibility
        this.guiItems = structuredClone(player.gui_items)
          .filter((x: GuiItem) => x.parameter_id == null)
          .filter(
            x =>
              x.player_island_id === null || (x.player_island_id != null && x.player_island_id === this.currentIslandId)
          )
          .map(guiItem => {
            let resourceBalance = null;

            // if this is currency then get the information about it and fill current CurrencyBalance(from user and player store)
            if (guiItem.currency_id) {
              const currency = this.currencyDefinitions.find(curr => curr.currency_id === guiItem.currency_id);
              resourceBalance = this.currencyService.getCurrencyBalance(guiItem, [
                ...player.currency_balances,
                ...this.user.currency_balances,
              ]);

              if (currency) {
                guiItem.currencyInformation = {
                  type: currency.type,
                  show_currency_history: currency.show_currency_history,
                  currency: R.mergeDeepRight(resourceBalance, currency),
                };
              }
            }

            // get balance of the product
            if (guiItem.product_id) {
              resourceBalance = this.findProductBalance(guiItem, player.product_balances);
            }

            guiItem = { ...guiItem, resourceBalance: resourceBalance?.balance ?? 0 };

            // set correct way of displaying tooltips
            this.setTooltip(guiItem);

            return guiItem;
          })
          .sort((a, b) => a.sequence - b.sequence);
      }
    );
  }

  openHistoryTransaction(currency: CurrencyBalance, tooltip?: NgbTooltip) {
    if (currency.show_currency_history) {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY.HISTORY,
        config: {
          data: { currency },
        },
        callback: () => {
          if (tooltip) {
            tooltip.close();
          }
        },
      });
    }
  }

  setTooltip(guiItem: HudGuiItem) {
    const productBalance = this.findProductBalance(guiItem, this.player["product_balances"]);
    if (guiItem.tooltip && guiItem.product_id) {
      Object.keys(this.PLACEHOLDERS).forEach(key => {
        const placeholderObject: { placeholder: string; getValue: (product?: any) => any } = this.PLACEHOLDERS[key];
        if (guiItem.tooltip.indexOf(placeholderObject.placeholder) > -1) {
          guiItem.tooltip = guiItem.tooltip.replace(
            placeholderObject.placeholder,
            placeholderObject.getValue(productBalance)
          );
        }
      });
    }
  }

  findProductBalance(guiItem: HudGuiItem, balances: any) {
    return balances.find(balance => balance.product_id === guiItem.product_id);
  }

  handleClickEvent(e: { item: HudGuiItem; tooltip?: NgbTooltip }) {
    if (e.item.product_id) {
      // on mobile open dialog
      if (this.isMobileMediaQuery) {
        this.dialogService.openAlert({
          description: e.item.tooltip,
        });
        return;
      }

      return;
    }

    if (e.item.currency_id) {
      this.openHistoryTransaction(e.item.currencyInformation.currency, e.tooltip);
    }
  }
}
