import { Component, OnInit } from "@angular/core";
import moment from "moment";

import { EVENTS } from "../../../../../../../../core/consts/core/events";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { SynchronizeTimeService } from "../../../../../../../../core/providers/synchronize-time.service";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { getToken } from "../../../../../../../../core/utility/token";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from "../../../../../rankings/consts/custom/event-dialogs/event-names.const";
import { BusinessRanksService } from "../../../../../rankings/services/custom/business-ranks.service";
import { AbstractCardTaskDetailsComponent } from "../../../../abstract/custom/abstract-card-task-details.component";
import { CURRENCY_ID_TO_DISPLAY } from "../../../../consts/custom/currency-id-to-display.const";
import { ChooseTaskComponent } from "../choose-task/choose-task.component";

@Component({
  selector: "app-card-task-details",
  templateUrl: "./card-task-details.component.html",
})
export class CardTaskDetailsComponent extends AbstractCardTaskDetailsComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(BusinessRanksService) businessRanksService: BusinessRanksService;
  allowChoose = true;

  ngOnInit() {
    if (this.data.task) {
      this.setTask(this.data.task);
    } else if (this.data.cardTask.selected) {
      this.setTask(this.data.cardTask.selected);
    }

    this.setCardTask(this.data.cardTask);

    if (this.data.cardTasks) {
      this.notEmptyCardTasks = this.data.cardTasks.filter(cardTask => {
        return cardTask && cardTask.selected;
      });

      this.setCurrentIndex();
    }

    this.checkChoosableTill();
  }

  openRank(rankEditionId) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_DETAILS_GAME,
      config: {
        data: {
          rankId: rankEditionId,
        },
      },
    });
  }

  cancelTask() {
    if (!this.globalService.isDevDomain && getToken().is_impersonate) {
      this.dialogService.openConfirm(
        {
          title: "Uwaga!",
          description: "Usuwasz zadanie wybieralne na produkcji. Czy jesteś tego pewien",
        },
        confirm => {
          if (confirm) {
            this.cancelTaskEndpoint();
          }
        }
      );
    } else {
      this.cancelTaskEndpoint();
    }
  }

  cancelTaskEndpoint() {
    this.apiBusinessService.cancelTask({ player_task_id: this.task.player_task_id }).subscribe(() => {
      this.dialogService.closeAll();
      this.emitReloadsCards();
    });
  }

  openChooseTasks() {
    if (this.cardTask.selected && this.task.player_task_id === this.cardTask.selected.player_task_id) {
      this.dialogService.open(ChooseTaskComponent, {
        data: {
          cardTask: this.cardTask,
        },
      });
    } else {
      this.matDialogRef.close();
    }
  }

  playerTasksChoose() {
    if (!this.globalService.isDevDomain && getToken().is_impersonate) {
      this.dialogService.openConfirm(
        {
          title: "Uwaga!",
          description: "Ustawiasz zadanie wybieralne na produkcji. Czy jesteś tego pewien",
        },
        confirm => {
          if (confirm) {
            this.playerTasksChooseEndpoint();
          }
        }
      );
    } else {
      this.playerTasksChooseEndpoint();
    }
  }

  playerTasksChooseEndpoint() {
    this.apiBusinessService
      .playerTasksChoose({
        player_task_id: this.task.player_task_id,
      })
      .subscribe(
        () => {
          this.dialogService.closeAll();
          this.emitReloadsCards();
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  openDescriptionDialog(text) {
    this.dialogService.openAlert({
      description: text,
      style: {
        maxWidth: "calc(350px * var(--multiply))",
        scrollHeight: "calc(270px * var(--multiply))",
      },
      isScrollbarOn: true,
    });
  }

  emitReloadsCards() {
    this.globalService.globalEvents.emit({ name: EVENTS.GUI.RELOAD_CARDS });
  }

  checkChoosableTill() {
    if (this.task.choosable_till) {
      this.allowChoose = moment(this.synchronizeTimeService.getActualLocalTime()).isSameOrBefore(
        moment(this.task.choosable_till)
      );
    }
  }
  openPdf() {
    if (this.task.pdf_url) {
      window.open(this.task.pdf_url, "_blank");
    }
  }

  protected readonly Math = Math;
  protected readonly CURRENCY_ID_TO_DISPLAY = CURRENCY_ID_TO_DISPLAY;
  protected readonly parseFloat = parseFloat;
}
