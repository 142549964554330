import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { SharedModule } from "../../../../../../../shared/shared.module";
import { ButtonBackComponent } from "../../../../../shared-ui/base/core/buttons/button-back/button-back.component";
import { CloseButtonComponent } from "../../../../../shared-ui/base/core/buttons/button-close/close-button.component";

@Component({
  selector: "app-question-more-info",
  templateUrl: "./question-more-info.component.html",
  styleUrl: "./question-more-info.component.scss",
  encapsulation: ViewEncapsulation.None,
  imports: [CloseButtonComponent, SharedModule, ButtonBackComponent],
  standalone: true,
})
export class QuestionMoreInfoComponent {
  constructor(
    public matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
