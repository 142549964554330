import { EVENT_DIALOGS_BUSINESS_NAMES } from "../../../../business/consts/custom/event-dialogs/event-names.const";
import { MenuButtonConfig } from "../../interfaces/core/menu-buttonts-config.interface";

export const CUSTOM_MENU_BUTTONS_CONFIG: MenuButtonConfig[] = [
  {
    type: "button",
    place: "menu-left",
    order: 10,
    name: "reward_codes",
    gui_notification_type: null,
    gui_unlocks_button: "scratch_cards_code",
    click_event: EVENT_DIALOGS_BUSINESS_NAMES.LIST,
    tooltip: "Kody z Nagrodami",
    icon: "codes_gift.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
    data: {
      type: "DIALOG",
    },
  },
];
