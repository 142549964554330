import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Action, select, Store } from "@ngrx/store";
import * as Sentry from "@sentry/angular";
import {defer, interval, Observable, of, switchMap} from 'rxjs';
import {catchError, concatMap, filter, map, mergeMap, take, tap} from 'rxjs/operators';

import { omitErrorResponseHelper } from "../../core/helpers/omit-error-response.helper";
import { CurrencyBalanceDetails } from "../../core/interfaces/currency";
import { CurrencyService } from "../../core/providers/currency.service";
import { ParametersService } from "../../core/providers/parameters.service";
import { SynchronizeTimeService } from "../../core/providers/synchronize-time.service";
import { RegisterCustomFirebaseTokenService } from "../../core/services/core/register-firebase-token.service";
import { Player } from "../../modules/player/interfaces/player";
import { PlayerService } from "../../modules/player/providers/player.service";
import { AppState } from "../state";
import { userUpdateCurrencyBalances, userUpdateDiscount } from "../user/actions";
import {
  utilitySetHasMissionsToCollect,
  utilitySetHasNewMessagesToRead,
  utilityUpdateActivePlayerId,
} from "../utility/actions";
import { UtilityPlayer } from "../utility/interfaces/utility-player";
import { selectUtilityPlayer } from "../utility/selectors";
import {
  playerFetch,
  playerFetchSuccess,
  playerNullAction,
  playerUpdate,
  playerUpdateTry,
  playerUpdateUnreadMessages,
} from "./actions";
import { customHandleHasMissionsToCollect } from "./custom/helpers/handle-has-missions-to-collect.helper";
import { customHandleUpdatePlayer } from "./custom/helpers/handle-update-player.helper";

@Injectable()
export class PlayerEffects {
  isCheckPlayerPointsBalance: boolean;

  constructor(
    private actions$: Actions,
    private playerService: PlayerService,
    private store: Store<AppState>,
    private currencyService: CurrencyService,
    private parametersService: ParametersService,
    private synchronizeTimeService: SynchronizeTimeService,
    private registerCustomFirebaseTokenService: RegisterCustomFirebaseTokenService
  ) {}

  $fetchPlayer: Observable<void | Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(playerFetch),
      this.waitForAssetsData(),
      concatLatestFrom(() => this.store.pipe(select(selectUtilityPlayer))),
      /**
       * If payload is empty, get active player id
       */
      map(([action, state]) => {
        if (!action.playerId) {
          return {
            playerId: state.activePlayerId,
          };
        } else {
          return action;
        }
      }),
      mergeMap(action => {
        return this.fetchPlayer({ playerId: action.playerId });
      })
    );
  });

  $updateUnreadMessages: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(playerUpdateUnreadMessages),
        tap(action => {
          this.store.dispatch(utilitySetHasNewMessagesToRead({ setHasNewMessagesToRead: action.messages[1] > 0 }));
        })
      ),
    { dispatch: false }
  );

  tryUpdatePlayer$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(playerUpdateTry),
      concatLatestFrom(() => this.store.pipe(select(selectUtilityPlayer))),
      tap(([action, state]) => {
        this.handleHasMissionsToCollect({ action, state });
      }),
      tap(([action, state]) => {
        // TODO - wpiąć wraz z rejestracja ze gra sie rozpoczela (native)
        // if (action.player.id === state.mePlayerId) {
        // this.registerCustomFirebaseTokenService.registerFirebase();
        // }
      }),
      map(([action, state]) => {
        return this.handleUpdatePlayer(JSON.parse(JSON.stringify(action.player)));
      }),
      tap(player => {
        if (player.user_currency_balances) {
          this.store.dispatch(userUpdateCurrencyBalances({ balances: player.user_currency_balances }));
        }

        if (player.current_discount_value !== undefined) {
          this.store.dispatch(userUpdateDiscount({ discount: player.current_discount_value }));
        }

        // if unread_messages is not falsy and there's some messages -> update it
        if (player.unread_messages != null && Object.keys(player?.unread_messages).length) {
          this.store.dispatch(playerUpdateUnreadMessages({ messages: player.unread_messages }));
        }
      }),
      map((player: Player) => {
        this.synchronizeTimeService.setTimeOffset(player.real_time);
        return playerUpdate({ player: player });
      })
    );
  });

  fetchPlayer(payload: { playerId: number }) {
    return this.playerService.getPlayer(payload.playerId).pipe(
      concatMap((playerData: Player) => {
        playerData.currency_balances = <CurrencyBalanceDetails[]>(
          this.currencyService.getCurrencyDefinitions(playerData.currency_balances)
        );
        this.playerService.player = playerData;

        Sentry.setContext("Player", {
          playerId: playerData?.id ?? "unknown",
          titleId: playerData?.title_id?.id ?? "unknown",
          branchId: playerData?.branch_id ?? "unknown",
        });

        return [
          playerFetchSuccess(),
          utilityUpdateActivePlayerId({ playerId: playerData.id }),
          playerUpdateTry({ player: playerData }),
        ];
      }),
      tap(() => {
        this.playerService.playerChange();
      }),
      catchError((error: any) => {
        return of(playerNullAction(omitErrorResponseHelper(error)));
      })
    );
  }

  handleHasMissionsToCollect({ action, state }: { action; state: UtilityPlayer }) {
    const coreValueHasMissionToCollect =
      state.hasMissionsToCollect ||
      action.player.missions_to_collect.filter(mission => mission.mission_type === 1).length > 0;

    this.store.dispatch(
      utilitySetHasMissionsToCollect({
        setMissionToCollect:
          customHandleHasMissionsToCollect({ action, state, coreValueHasMissionToCollect }) ||
          (!this.isCheckPlayerPointsBalance && action.player.points_balance === 0),
      })
    );

    this.isCheckPlayerPointsBalance = true;
  }

  handleUpdatePlayer(player: Player) {
    customHandleUpdatePlayer(player);
    this.parametersService.setParametersBalances(player.parameter_balances);
    player.parameter_balances = this.parametersService.playerParametersBalances$.value;
    player.currency_balances = <CurrencyBalanceDetails[]>(
      this.currencyService.getCurrencyDefinitions(player.currency_balances)
    );
    this.playerService.player = player;
    return player;
  }

  waitForAssetsData() {
    return switchMap(() =>
      defer(() =>
        interval(100).pipe(
          map(() => window['assets']),
          filter(value => value !== undefined && value !== null),
          take(1)
        )
      )
    );
  }
}
