import moment from "moment";
import * as R from "ramda";

import { OwDate } from "../../../../../../core/utility/ow-date.class";
import { CardTask } from "../../interfaces/custom/card-task.interface";

export function setDailyHelper(cardTask: CardTask): CardTask {
  if (cardTask.card_type === "daily") {
    cardTask.dailyHelper = {
      sumResourceScopes: null,
      baseScope: null,
      lastCalculation: null,
      nextCalculation: null,
    };

    if (cardTask.calculated_days && cardTask.calculated_days.length) {
      cardTask.selected = cardTask.calculated_days[0];

      cardTask.calculated_days.forEach(item => {
        const scopeRealization = item.scopes.find(scope => scope.scope_id === item.realized_scope_id);

        if (scopeRealization) {
          scopeRealization.currency_prizes.forEach(currency => {
            if (!cardTask.dailyHelper.sumResourceScopes) {
              cardTask.dailyHelper.sumResourceScopes = { currencies: [] };
            }

            const resultCurrency = cardTask.dailyHelper.sumResourceScopes.currencies.find(
              c => c.currency_id === currency.currency_id
            );

            if (resultCurrency) {
              resultCurrency.amount += currency.amount;
            } else {
              cardTask.dailyHelper.sumResourceScopes.currencies.push(R.clone(currency));
            }
          });
        }

        if (item.is_cleared) {
          cardTask.dailyHelper.lastCalculation = {
            date: item.calculated_date,
          };
        }
      });

      if (!cardTask.dailyHelper.lastCalculation) {
        const currentDate = new OwDate().momentDate.startOf("day");

        const nextDays = cardTask.calculated_days
          .map(item => {
            const diffDay = moment(item.calculated_date).startOf("day").diff(currentDate, "day");

            return {
              ...item,
              diffDay,
            };
          })
          .filter(item => item.diffDay > 0);

        const nextDay = nextDays[0];

        if (nextDay) {
          cardTask.dailyHelper.nextCalculation = {
            date: nextDay.calculated_date,
          };

          cardTask.dailyHelper.baseScope = nextDay.scopes.find(scope => scope.default);
        }
      }
    }
  }

  return cardTask;
}
